import React, { useState, useEffect } from 'react';
import { seguWalletLogo } from "../global";
import { getHolders, updateHolder, deleteHolder, getHolderByOrganization } from '../api/PolicyHolderService';
import { getPoliciesByHolder } from '../api/policyService';
import { getSubgroupByPolicyId } from "../api/SubgroupService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PolicyHolderForm from './PolicyHolderForm';
import { pdfLogo } from "../global";
import CertificatesForm from './CertificatesForm';
import { deleteCertificate, getCertificatesBySubgroup, sendCertificate, sendCertificateWithId, sendToAdminPolicy, updateCertificate, uploadCertificate } from '../api/CertificateService';
import { getOrganizations } from '../api/OrganizationService';
import { Modal, Button, Form, Accordion } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { deletePDF } from '../api/PdfService';
import { format } from 'date-fns';
import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
import ModalInsuredData from "../features/certificate/ModalInsuredData";
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';
import ModalAddInsured from '../features/certificate/ModalAddInsured';
import ModalAddEmail from '../features/certificate/ModalAddEmail';
import ModalAddDependent from '../features/certificate/ModalAddDependent';
import SubgroupExcelUpload from './Excel/SubgroupExcelUpload';
import { sendInvitation } from '../api/emailService';
import { AttachEmail } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

const MySwal = withReactContent(Swal);

function CertificateList() {
  const dispatch = useDispatch();
  const { getRootProps, getInputProps, isDragActive } = useDropzone();
  const organization = useSelector((state) => state.organizations.data);
  const user = useSelector((state) => state.users.data);

  const [policyHolders, setPolicyHolders] = useState([]); // Estado para almacenar los contratantes
  const [policies, setPolicies] = useState([]); // Estado para almacenar las pólizas
  const [selectedHolder, setSelectedHolder] = useState(''); // Estado para el contratante seleccionado
  const [selectedPolicy, setSelectedPolicy] = useState(''); // Estado para la póliza seleccionada
  const [selectedSubgroup, setSelectedSubgroup] = useState(''); // Estado para el subgrupo seleccionado
  const [subgroups, setSubgroups] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [orgDatas, setOrgDatas] = useState([]);
  const [buttonAdd, setButtonAdd] = useState(false); // Estado para controlar la visualización del formulario

  const [editingId, setEditingId] = useState(null);
  const [originalData, setOriginalData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');
  const [selectedId, setSelectedId] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [certificateId, setCertificateId] = useState(null);
  const canDeleteOrCreate = user === 'admin' || user === 'executive';
  const [showModalSecondary, setShowModalSecondary] = useState(false);
  const [showModalTertiary, setShowModalTertiary] = useState(false);
  const [showModalFourthiary, setShowModalFourthiary] = useState(false);
  const [showModalFifth, setShowModalFifth] = useState(false);

  const [excelUploadOpen, setExcelUploadOpen] = useState(false);

  const handleShowSecondary = (id) => {
    setSelectedId(id);
    setShowModalSecondary(true);
  };
  const handleShowTertiary = (id) => {
    setSelectedId(id);
    setShowModalTertiary(true);
  };
  const handleShowFourthiary = (id) => {
    setSelectedId(id);
    setShowModalFourthiary(true);
  };
  const handleShowFifth = (id) => {
    setSelectedId(id);
    setShowModalFifth(true);
  };
  const handleCloseFourthiary = () => setShowModalFourthiary(false);
  const handleCloseSecondary = () => setShowModalSecondary(false);
  const handleCloseTertiary = () => setShowModalTertiary(false);
  const handleCloseFifth = () => setShowModalFifth(false);

  const categoryMap = {
    Certificado: 'Certificado',
    Credencial_Digital: 'Credencial Digital',
    Carta_Antiguedad: 'Carta de Antigüedad',
    Otros: 'Otros'
  };

  // Función para filtrar PDFs por categoría
  const filterPdfsByCategory = (categoryKey) => {
    return formData.pdfs.filter((pdf) => pdf.category === categoryKey);
  };

  useEffect(() => {
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization)
    }
    if (!user.length) {
      infoGlobal.fetchRole();
    }
  }, [organization, user, dispatch]);



  const handleOrganizationChange = async (selectedOrgId) => {
    try {
      setSelectedOrganization(selectedOrgId);
      console.log("ID DESDE ORG>JS PRUEBNA", selectedOrgId)
      const data = await getHolderByOrganization(selectedOrgId);
      setPolicyHolders(data.holders);
    } catch (error) {
      console.error(error)
    }
  };
  const handlePolicyHolderChange = async (event) => {
    try {
      const selectedPolicyHolder = event.target.value;
      console.log("selectedPolicyHolder", selectedPolicyHolder);
      setSelectedPolicyHolder(selectedPolicyHolder);
      if (!selectedPolicyHolder == '') {
        //obtener las polizas dado el id del policy holder
        const data = await getPoliciesByHolder(selectedPolicyHolder);
        console.log("Data de getPoliciesByHolder", data)
        setPolicies(data);
      } else {
        setDataFetched(false)
      }
    } catch (error) {
      console.error('Error handling policyHolder change:', error);
    }
  };
  const handlePolicyChange = async (event) => {
    try {
      const selectedPolicy = event.target.value;
      console.log("selectedPolicy", selectedPolicy);
      setSelectedPolicy(selectedPolicy);
      if (!selectedPolicy == '') {
        const dataSubgroups = await getSubgroupByPolicyId(event.target.value);
        console.log("Data sub", dataSubgroups);
        setSubgroups(dataSubgroups);
      } else {
        setDataFetched(false)
      }
    } catch (error) {
      console.error('Error fetching subgroups:', error);
    }
  };

  const handleSubgorupChange = async (event) => {
    try {
      const selectedSubgroup = event.target.value;
      console.log("selectedSubgroup", selectedSubgroup);
      setSelectedSubgroup(selectedSubgroup);
      if (selectedSubgroup == '') {
        setDataFetched(false);
      }
    } catch (error) {
      console.error('Error handling subgroup change:', error);
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const certificateToEdit = certificates.find((certificate) => certificate.id_certificate === id);
    setFormData({ ...certificateToEdit });
    setShowModal(true);
  };
  const handleSendCertificate = async (id) => {
    try {
      await sendCertificateWithId(id);

      Swal.fire({
        title: '¡Éxito!',
        text: 'El certificado se ha enviado correctamente. ¿Quieres enviarlo al administrador de la póliza?',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar al administrador de la póliza',
        cancelButtonText: 'No, cerrar',
        icon: 'success',
      }).then((result) => {
        if (result.isConfirmed) {
          sendAdminCertificate(id);
        } else {
          handleClose(); // Cierra el modal si no se desea enviar al administrador
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };
  const sendAdminCertificate = async (id) => {
    try {
      await sendToAdminPolicy(id);
      Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El certificado se ha enviado correctamente. ¿Quieres enviar a otra persona?',
        showCancelButton: true,
        confirmButtonText: 'Sí, enviar a otra persona',
        cancelButtonText: 'No, cerrar',
      }).then((result) => {
        if (result.isConfirmed) {
          sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
        } else {
          Swal.close(); // Cierra el Swal si no se desea enviar a otra persona
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };
  const sendAnotherEmail = async (id) => {
    try {
      // Solicitar el correo electrónico y enviar el certificado
      Swal.fire({
        icon: 'info',
        title: 'Enviar a otra persona',
        text: 'Introduce el email de la nueva persona',
        input: 'email',
        inputLabel: 'Correo electrónico',
        inputPlaceholder: 'Correo electrónico',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Enviar',
        inputValidator: (value) => {
          if (!value) {
            return 'El correo electrónico es obligatorio';
          }
          return null;
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newEmail = result.value;
          if (newEmail) {
            try {
              // Enviar el certificado al nuevo correo
              await sendToAdminPolicy(id, { email: newEmail });
              Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'El certificado se ha enviado correctamente a la nueva persona.',
              }).then(() => {
                sendAnotherEmail(id); // Vuelve a preguntar para enviar a otra persona
              });
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al enviar el certificado.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
              });
            }
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Ha ocurrido un error al enviar el certificado.',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
      });
    }
  };

  const openPdf = (pdfUrl) => {
    const newWindow = window.open(pdfUrl);
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.fetch(pdfUrl, {
        });
      };
    }
  };

  const handleCancelEdit = () => {
    setEdit(false);
    setEditingId(null);
    setUpdatedData({});
  };
  const handleEditSecundary = () => {
    setEdit(true);
  };

  const handleSaveEdit = async () => {
    try {
      console.log("edditingID", editingId)
      await updateCertificate(editingId, updatedData);
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(fetchCertificates());
      setShowModal(false);
      setEdit(false);
    } catch (error) {
      console.error('Error updating certificate:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar el Certificado?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCertificate(id);
          setCertificates(prev => prev.filter(certificates => certificates.id_certificate !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El certificado se ha eliminado correctamente.',
          }).then();
        } catch (error) {
          console.error('Error deleting certificates:', error.response.data.message);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            html: `Ha ocurrido un error al eliminar El certificado.<br/><strong>${error.response.data.message}</strong>`,
          });

        }
      }
    });
  };

  const fetchCertificates = async () => {
    if (subgroups) {
      try {
        const data = await getCertificatesBySubgroup(selectedSubgroup);
        console.log("DAta de 200", data)
        setCertificates(data);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      }
    }
  };

  const handleClose = async () => {
    setShowModal(false)
    setEdit(false);
  }
  const handleFieldChange = (fieldName, value) => {
    setUpdatedData((prevData) => ({ ...prevData, [fieldName]: value }));
  };
  const handleDeletePDF = async (id, pdfId) => {
    try {
      await deletePDF(pdfId);
      console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
      alert("anadir el recargar las polizas")
    } catch (error) {
      console.error('Error deleting PDF:', error);
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        title: '¡Error!',
        text: 'Ha ocurrido un error al eliminar el PDF.',
      });
    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (selectedFile && certificateId) {
      const response = await uploadCertificate(certificateId, selectedFile); // Pasar ID del certificado y archivo al handler
      console.log(response)
      closeUploadModal();
    }
  };
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'yyyy-MM-dd');
    } catch (error) {
      console.error('Error formateando la fecha:', error);
      return '';
    }
  };
  const openUploadModal = (certificateId) => {
    setCertificateId(certificateId); // Guardar el ID del certificado
    setShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    setSelectedFile(null); // Limpiar el archivo seleccionado al cerrar el modal
    setCertificateId(null); // Limpiar el ID del certificado
  };
  const handleOpenUploadExcel = () => {
    setDataFetched(false);
    setExcelUploadOpen(true)
  }

  const handleDataSearch = async () => {
    setDataFetched(true);
    if (!selectedSubgroup == '') {
      //obtener las polizas dado el id del policy holder
      const data = await getCertificatesBySubgroup(selectedSubgroup);
      console.log("Certificado DATA", data)
      setCertificates(data);
    }
    setExcelUploadOpen(false)
  }

  const handleSendInvitation = async (certificate_id, emailToSend, name) => {
    const formData = {
      emailToSend: emailToSend,
      first_name: name
    };
    try {
      await sendInvitation(certificate_id, formData);
      alert('Invitación SeguWallet enviada correctamente');
    } catch (error) {
      alert(error)
    }
  }
  return (
    <div>
      <div className="w-100 px-3 mx-auto">
        <CertificatesForm />
        <ModalInsuredData showModal={showModalSecondary} handleClose={handleCloseSecondary} selectedId={selectedId} />
        <ModalAddInsured showModal={showModalTertiary} handleClose={handleCloseTertiary} selectedId={selectedId} fetchCertificates={fetchCertificates} />
        <ModalAddEmail showModal={showModalFourthiary} handleClose={handleCloseFourthiary} selectedId={selectedId} />
        <ModalAddDependent showModal={showModalFifth} handleClose={handleCloseFifth} certificate_id={selectedId} fetchCertificates={fetchCertificates} />


        <div className="row">
          <div className="col-md-6">
            <SearchOrganization onSelect={handleOrganizationChange} />
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                name="PolicyHolder_id"
                value={selectedPolicyHolder}
                onChange={handlePolicyHolderChange}
                className="form-control"
                id="PolicyHolder_id"
              >
                <option value="">Selecciona un Contratante</option>
                {policyHolders.map((holder) => (
                  <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                    {holder.id_policy_holder} - {holder.business_name}
                  </option>
                ))}
              </select>
              <label htmlFor="PolicyHolder_id">Contratante</label>

            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                name='policy_id'
                value={selectedPolicy}
                onChange={handlePolicyChange}
                className="form-control"
                id="policy_id"

              >
                <option value="">Selecciona una Póliza</option>
                {policies.map(policy => (
                  <option key={policy.id_policy} value={policy.id_policy}>
                    {policy.policy_number}
                  </option>
                ))}
              </select>
              <label htmlFor="policy_id">Póliza</label>
            </div>

          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3">
              <select
                name="subgroup_id"
                value={selectedSubgroup}
                onChange={handleSubgorupChange}
                className="form-control"
                id="subgroup_id"
              >
                <option value="">Selecciona un Subgrupo</option>
                {subgroups.map((subgroup) => (
                  <option key={subgroup.id_subgroup} value={subgroup.id_subgroup}>
                    {subgroup.subgroup_number} - {subgroup.subgroup_name}
                  </option>
                ))}
              </select>
              <label htmlFor="subgroup_id">Subgrupo Numero-Nombre</label>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {selectedSubgroup && (
              <div className="d-flex justify-content mb-3">
                <button className="btn btn-info w-100" onClick={() => handleDataSearch()}>Buscar</button>
                <button className="btn btn-success w-100" onClick={() => handleOpenUploadExcel()}>Subir Excel</button>
              </div>
            )}
          </div>
        </div>
        {excelUploadOpen && (
          <SubgroupExcelUpload subgroup_id={selectedSubgroup} />
        )}
        {dataFetched && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Número del Certificado</th>
                <th scope="col">Titular</th>
                <th scope="col">Status</th>
                <th scope="col">Creacion</th>
                <th scope="col">Email</th>
                <th scope="col">Detalles</th>
                <th scope="col">Acciones</th>
                <th scope="col">Información</th>
                <th scope="col">Correos</th>
              </tr>
            </thead>
            <tbody>
              {certificates.map((certificate) => (
                <tr key={certificate.id_certificate}>
                  <td>{certificate.certificate_number}</td>
                  <td>{certificate.holder}</td>
                  <td>{certificate.status === 'released' ? 'Liberado' : 'En Proceso'}</td>
                  <td>{formatDate(certificate.created_at)}</td>
                  <td>{certificate.email}</td>
                  <td>{certificate.details}</td>
                  <td>
                    {certificate.pdfs == 0 && (
                      <button className="btn btn-secondary btn-sm me-1" onClick={() => openUploadModal(certificate.id_certificate)}>Subir Certificado</button>
                    )}

                    {certificate.holder == null && (
                      <button className="btn btn-info btn-sm me-1" onClick={() => handleShowTertiary(certificate.id_certificate)}>Añadir Titular</button>
                    )}

                    {certificate.holder != null && (
                      <button className="btn btn-info btn-sm me-1" onClick={() => handleShowFifth(certificate.id_certificate)}>Añadir Dependiente</button>
                    )}

                  </td>
                  <td>
                    <div>
                      <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(certificate.id_certificate)}>Ver Completo</button>
                      <button className="btn btn-danger btn-sm me-1" onClick={() => handleDelete(certificate.id_certificate)}><DeleteIcon /></button>
                    </div>
                  </td>
                  <td>
                    {certificate.pdfs.length != 0 && certificate.email && (
                      <div>
                        <button className="btn btn-primary btn-sm me-1" onClick={() => handleSendCertificate(certificate.id_certificate)}>
                          <AttachEmail />
                        </button>

                        <button
                          className="btn btn-sm me-1"
                          onClick={() => handleSendInvitation(certificate.id_certificate, certificate.email, certificate.holder)}
                          style={{ width: '15%' }}
                        >
                          <img src={seguWalletLogo} alt="Icono" style={{ width: '100%', objectFit: 'contain' }} />
                        </button>

                      </div>
                    )}
                    {!certificate.email && (
                      <button className="btn btn-success btn-sm me-1" onClick={() => handleShowFourthiary(certificate.id_certificate)}>Añadir Email</button>
                    )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Modal dialogClassName="modal-lg" show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Certificado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <Form.Group controlId="formName">
                    <Form.Label>Numero del certificado</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={formData.certificate_number}
                      onChange={(e) => handleFieldChange('certificate_number', e.target.value)}
                      disabled={!edit}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-4">
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email del asegurado</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={formData.email}
                      onChange={(e) => handleFieldChange('email', e.target.value)}
                      disabled={!edit}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-4">
                  <Form.Group controlId="formStatus">
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control
                      as="select"
                      value={formData.status}
                      onChange={(e) => handleFieldChange('status', e.target.value)}
                      disabled={!edit}
                    >
                      <option value="'">Selecciona el Estatus</option>
                      <option value="Por Iniciar">Por Iniciar</option>
                      <option value="Vigente">Vigente</option>
                      <option value="Baja">Baja</option>
                      <option value="Vencida">Vencida</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <Form.Group controlId="formDetails">
                    <Form.Label>Detalles</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={formData.details}
                      onChange={(e) => handleFieldChange('details', e.target.value)}
                      disabled={!edit}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-4">
                  <Form.Group controlId="formHolder">
                    <Form.Label>Titular</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={formData.holder}
                      onChange={(e) => handleFieldChange('holder', e.target.value)}
                      disabled={!edit}
                    />
                  </Form.Group>
                </div>

                {formData.insureds && formData.insureds.length > 0 && (
                  <Accordion className="mt-3">
                    {formData.insureds.map((insured, insuredIndex) => (
                      <Accordion.Item eventKey={insuredIndex} key={insured.id_insured}>
                        <Accordion.Header>Asegurado {insuredIndex + 1} {insured.relationship}</Accordion.Header>
                        <Accordion.Body>
                          <div className="w-100 px-3 mx-auto">
                            {/* Primer conjunto de columnas */}
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`first_name_${insured.id_insured}`}
                                    name="first_name"
                                    className="form-control"
                                    placeholder="Primer nombre"
                                    defaultValue={insured.first_name || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`first_name_${insured.id_insured}`}>Primer nombre</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`last_name_paternal_${insured.id_insured}`}
                                    name="last_name_paternal"
                                    className="form-control"
                                    placeholder="Apellido Paterno"
                                    defaultValue={insured.last_name_paternal || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`last_name_paternal_${insured.id_insured}`}>Apellido Paterno</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`last_name_maternal_${insured.id_insured}`}
                                    name="last_name_maternal"
                                    className="form-control"
                                    placeholder="Apellido Materno"
                                    defaultValue={insured.last_name_maternal || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`last_name_maternal_${insured.id_insured}`}>Apellido Materno</label>
                                </div>
                              </div>
                            </div>

                            {/* Segundo conjunto de columnas */}
                            <div className="row mt-3">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`relationship_${insured.id_insured}`}
                                    name="relationship"
                                    className="form-control"
                                    placeholder="Relación"
                                    defaultValue={insured.relationship || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`relationship_${insured.id_insured}`}>Relación</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="date"
                                    id={`date_birth_${insured.id_insured}`}
                                    name="date_birth"
                                    className="form-control"
                                    placeholder="Fecha de Nacimiento"
                                    defaultValue={insured.date_birth || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`date_birth_${insured.id_insured}`}>Fecha de Nacimiento</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="date"
                                    id={`current_antiquity_${insured.id_insured}`}
                                    name="current_antiquity"
                                    className="form-control"
                                    placeholder="Antigüedad Actual"
                                    defaultValue={insured.current_antiquity || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`current_antiquity_${insured.id_insured}`}>Antigüedad Actual</label>
                                </div>
                              </div>
                            </div>

                            {/* Tercer conjunto de columnas */}
                            <div className="row mt-3">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="date"
                                    id={`total_antiquity_${insured.id_insured}`}
                                    name="total_antiquity"
                                    className="form-control"
                                    placeholder="Antigüedad Total"
                                    defaultValue={insured.total_antiquity || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`total_antiquity_${insured.id_insured}`}>Antigüedad Total</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <select
                                    id={`gender_${insured.id_insured}`}
                                    name="gender"
                                    className="form-control"
                                    defaultValue={insured.gender || 'NA'}
                                    disabled
                                  >
                                    <option value="">Seleccionar Género</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Femenino</option>
                                    <option value="O">Otro</option>
                                  </select>
                                  <label htmlFor={`gender_${insured.id_insured}`}>Género</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="email"
                                    id={`email_${insured.id_insured}`}
                                    name="email"
                                    className="form-control"
                                    placeholder="Correo"
                                    defaultValue={insured.email || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`email_${insured.id_insured}`}>Email</label>
                                </div>
                              </div>
                            </div>

                            {/* Cuarto conjunto de columnas */}
                            <div className="row mt-3">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="email"
                                    id={`work_email_${insured.id_insured}`}
                                    name="work_email"
                                    className="form-control"
                                    placeholder="Email de Trabajo"
                                    defaultValue={insured.work_email || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`work_email_${insured.id_insured}`}>Email de Trabajo</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`rfc_${insured.id_insured}`}
                                    name="rfc"
                                    className="form-control text-uppercase"
                                    placeholder="RFC"
                                    defaultValue={insured.rfc || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`rfc_${insured.id_insured}`}>RFC</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`curp_${insured.id_insured}`}
                                    name="curp"
                                    className="form-control text-uppercase"
                                    placeholder="CURP"
                                    defaultValue={insured.curp || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`curp_${insured.id_insured}`}>CURP</label>
                                </div>
                              </div>
                            </div>

                            {/* Quinto conjunto de columnas */}
                            <div className="row mt-3">
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`phone_office_${insured.id_insured}`}
                                    name="phone_office"
                                    className="form-control"
                                    placeholder="Teléfono de Oficina"
                                    defaultValue={insured.phone_office || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`phone_office_${insured.id_insured}`}>Teléfono de Oficina</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-2">
                                  <input
                                    type="text"
                                    id={`phone_personal_${insured.id_insured}`}
                                    name="phone_personal"
                                    className="form-control"
                                    placeholder="Teléfono Personal"
                                    defaultValue={insured.phone_personal || 'NA'}
                                    disabled
                                  />
                                  <label htmlFor={`phone_personal_${insured.id_insured}`}>Teléfono Personal</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )}
              </div>


              <Form.Group>
                {showModal && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {Object.values(categoryMap).map((category, index) => (
                          <th key={index}>{category}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Object.keys(categoryMap).map((categoryKey, index) => (
                          <td key={index}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {filterPdfsByCategory(categoryKey).map((pdf, pdfIndex) => (
                                <div key={pdfIndex} style={{ marginBottom: '10px' }}>
                                  <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                    <img src={pdfLogo} alt="PDF" style={{ width: '28px', marginRight: '10px' }} />
                                    <br />
                                    <span className="text-sm">{pdf.file_name}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                )}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="ms-auto">
              {!edit ? (
                <div>
                  <Button variant="primary" onClick={handleEditSecundary} className="me-2">
                    Editar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button variant="success" onClick={handleSaveEdit} className="me-2">
                    Guardar
                  </Button>
                  <Button variant="danger" onClick={handleCancelEdit}>
                    Cancelar
                  </Button>
                </div>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {/* Modal para subir certificado */}
        <Modal show={showUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Subir Certificado PDF</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Seleccionar archivo PDF:</Form.Label>
              <input
                type="file"
                accept="application/pdf"
                multiple={false}
                className="form-control"
                onChange={handleFileChange}
              />


            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeUploadModal}>
              Cancelar
            </Button>
            {selectedFile && (

              <Button variant="primary" accept="application/pdf" onClick={handleUpload}>
                Subir
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div >
  );
}

export default CertificateList;
