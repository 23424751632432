import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AddIcon from '@mui/icons-material/Add';
import InsuredForm from './InsuredForm';
import CertificatesForm from './CertificatesForm';
import SubgroupForm from './SubgroupForm';
import SubgroupExcelUpload from './Excel/SubgroupExcelUpload';
import { Edit } from '@mui/icons-material';
import ModalEditPolicie from './Policies/ModalEditPolicies';
import ModalEditPolicyFiles from './Policies/ModalEditPolicyFiles';
import ModalEditSubgroup from './Policies/ModalEditSubgroups';
import ModalEditPolicyHolder from './Policies/ModalEditPolicyHolders';

const ActionsPanel = ({ policyData, updateData }) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const buttonRefAdd = useRef(null);
    const menuRefAdd = useRef(null);

    const buttonRefEdit = useRef(null);
    const menuRefEdit = useRef(null);

    const [showSubgroup, setShowSubgroup] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const [showInsured, setShowInsured] = useState(false);
    const [showExcelUpload, setShowExcelUpload] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showEditFiles, setShowEditFiles] = useState(false);
    const [showEditSubgroups, setShowEditSubgroups] = useState(false);
    const [showEditPolicyHolder, setShowEditPolicyHolder] = useState(false);

    const toggleMenuAdd = () => {
        setOpenAdd(!openAdd);
    };
    const toggleMenuEdit = () => {
        setOpenEdit(!openEdit);
    };


    const handleClickOutside = (event) => {
        if (
            (menuRefAdd.current && !menuRefAdd.current.contains(event.target) &&
                buttonRefAdd.current && !buttonRefAdd.current.contains(event.target)) ||
            (menuRefEdit.current && !menuRefEdit.current.contains(event.target) &&
                buttonRefEdit.current && !buttonRefEdit.current.contains(event.target))
        ) {
            setOpenAdd(false);
            setOpenEdit(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const handleSubgroupOpen = () => setShowSubgroup(true);
    const handleSubgroupClose = () => { setShowSubgroup(false); updateData() }
    const handleCertificatesOpen = () => setShowCertificates(true);
    const handleCertificateClose = () => { setShowCertificates(false); updateData() }
    const handleInsuredOpen = () => setShowInsured(true);
    const handleInsuredClose = () => { setShowInsured(false); updateData() };
    const handleExcelOpen = () => setShowExcelUpload(true);
    const handleExcelClose = () => { setShowExcelUpload(false); updateData() };
    const handleEditOpen = () => setShowEdit(true);
    const handleEditClose = () => { setShowEdit(false); updateData() };
    const handleEditFileOpen = () => { setShowEditFiles(true); }
    const handleEditFileClose = () => { setShowEditFiles(false); updateData(); }
    const handleEditSubgroupOpen = () => { setShowEditSubgroups(true); }
    const handleEditSubgroupClose = () => { setShowEditSubgroups(false); updateData(); }
    const handleEditPolicyHolderOpen = () => { setShowEditPolicyHolder(true); }
    const handleEditPolicyHolderClose = () => { setShowEditPolicyHolder(false); updateData(); }
    return (
        <div>
            <Button
                ref={buttonRefAdd}
                onClick={toggleMenuAdd}
                variant="success"
                style={{
                    position: 'fixed',
                    top: '50px',
                    right: '20px',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    zIndex: 1030, // Por encima de otros elementos
                }}
            >
                <AddIcon />
            </Button>

            <Button
                ref={buttonRefEdit}
                onClick={toggleMenuEdit}
                variant="warning"
                style={{
                    position: 'fixed',
                    top: '50px',
                    right: '85px',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    zIndex: 1000, // Por encima de otros elementos
                }}
            >
                <Edit />
            </Button>

            {openAdd && (
                <div
                    ref={menuRefAdd}
                    style={{
                        position: 'fixed',
                        top: '90px',
                        right: '20px',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 12px rgba(0,0,0,0.15)',
                        borderRadius: '12px',
                        padding: '10px',
                        zIndex: 1040,
                    }}
                >
                    <Stack gap={2}>
                        <Button
                            variant="success"
                            onClick={handleSubgroupOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Subgrupo
                        </Button>
                        <Button
                            variant="warning"
                            onClick={handleCertificatesOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Certificado
                        </Button>
                        <Button
                            variant="dark"
                            onClick={handleInsuredOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <AddIcon style={{ marginRight: '5px' }} /> Asegurado
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleExcelOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <FilePresentIcon style={{ marginRight: '5px' }} /> Cargar Datos
                        </Button>
                    </Stack>
                </div>
            )}
            {openEdit && (
                <div
                    ref={menuRefEdit}

                    style={{
                        position: 'fixed',
                        top: '90px',
                        right: '20px',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 12px rgba(0,0,0,0.15)',
                        borderRadius: '12px',
                        padding: '10px',
                        zIndex: 1040,
                    }}
                >
                    <Stack gap={2}>
                        <Button
                            variant="secondary"
                            onClick={handleEditOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <Edit style={{ marginRight: '5px' }} /> Editar Póliza
                        </Button>

                        <Button
                            variant="success"
                            onClick={handleEditFileOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <Edit style={{ marginRight: '5px' }} /> Archivos de la Póliza
                        </Button>
                        <Button
                            variant="warning"
                            onClick={handleEditSubgroupOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <Edit style={{ marginRight: '5px' }} /> Subgrupos
                        </Button>
                        <Button
                            variant="warning"
                            onClick={handleEditPolicyHolderOpen}
                            style={{ borderRadius: '12px' }}
                        >
                            <Edit style={{ marginRight: '5px' }} /> Contratante
                        </Button>
                    </Stack>
                </div>
            )}
            <Modal show={showExcelUpload} onHide={handleExcelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Asegurados # Póliza: {policyData.policy_number} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubgroupExcelUpload policy_id={policyData.id_policy} onClose={handleExcelClose} />
                    <button
                        className="btn btn-warning w-100"
                        onClick={() => window.location.href = 'https://seguwallet.grmseguros.mx/storage/files/6704484f05897_Plantilla-Carga-Certificados-07102024.xlsx'}
                    >
                        Descargar Plantilla
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleExcelClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                showSubgroup && (
                    <SubgroupForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleSubgroupClose}
                    />
                )
            }
            {
                showCertificates && (
                    <CertificatesForm
                        buttonAddForeign={true}
                        organizationForeign={policyData?.organization_id}
                        policyHolderForeign={policyData?.policy_holder_id}
                        policyForeign={policyData?.id_policy}
                        onClose={handleCertificateClose}
                    />
                )
            }
            {
                showInsured && (
                    <InsuredForm
                        showModal={showInsured}
                        policyForeign={policyData?.id_policy}
                        policyData={policyData}
                        onClose={handleInsuredClose}
                    />
                )
            }
            {
                showEdit && (
                    <ModalEditPolicie
                        data={policyData}
                        showModal={showEdit}
                        closeModal={handleEditClose}
                    />
                )
            }
            {
                showEditFiles && (
                    <ModalEditPolicyFiles
                        data={policyData}
                        show={showEditFiles}
                        close={handleEditFileClose}
                    />
                )
            }
            {
                showEditSubgroups && (
                    <ModalEditSubgroup
                        data={policyData}
                        showModal={showEditSubgroups}
                        onClose={handleEditSubgroupClose}
                    />
                )
            }
            {
                showEditPolicyHolder && (
                    <ModalEditPolicyHolder
                        data={policyData}
                        showModal={showEditPolicyHolder}
                        onClose={handleEditPolicyHolderClose}
                    />
                )
            }
        </div>
    );
};

export default ActionsPanel;
