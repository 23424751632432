import './Helpers/modalCustom.css'

import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { postPolicy } from '../api/policyService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getHolderByOrganization, getHolders } from '../api/PolicyHolderService';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../global';
import { getPools } from '../features/pools/poolService';
import { Button, Modal, Form, Col, Row, Accordion } from 'react-bootstrap';
import { RenderDropzone } from '../features/pdfs/functionsPdf';
import { initialPolicyData } from './Helpers/initialData';
import { types } from './Helpers/policyData';
import PoolForm from './Admin/Pools/PoolForm';
import { format, addYears } from 'date-fns'; // Importa las funciones necesarias de date-fns
import DateField from './Helpers/DateField';

const MySwal = withReactContent(Swal);

function PolicyForm({ onClose, buttonAddForeign, organizationForeign, refresh }) {

  const organization = useSelector((state) => state.organizations.data);
  const insurers = useSelector((state) => state.insurer.data);
  const executives = useSelector((state) => state.executive.data);

  const uniqueId = useMemo(() => `Policy${Math.floor(Math.random() * Date.now())}`, []);

  const [buttonAdd, setButtonAdd] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [formData, setFormData] = useState(initialPolicyData);
  const [insurersDatas, setInsurers] = useState([]);
  const [orgDatas, setOrgDatas] = useState([]);
  const [executivesDatas, setExecutives] = useState([]);
  const [holders, setHolders] = useState([]);
  const [file, setFile] = useState(null);
  const [excel, setExcel] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [type, setType] = useState(null);
  const [pools, setPools] = useState([]);

  const [typeOptions, setTypeOptions] = useState([]);
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [showModalPools, setShowModalPools] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const fileInputRef = useRef(null); // Referencia para el input

  const categories = {
    'Caratula': 'Caratula',
    'Endoso': 'Endoso',
    'Certificado': 'Certificados',
    'Credencial_Digital': 'Credencial Digital',
    'Recibo': 'Recibo',
    'Factura': 'Factura de Póliza',
    'Presentacion': 'Presentación',
    'Otros': 'Otros',
  };
  // Convertir a mayúsculas solo para campos específicos
  const upperCaseFields = ['policy_holder'];

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data if arrays are empty
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization);
    }

    if (!insurers.length) {
      infoGlobal.fetchInsurers();
    } else {
      setInsurers(insurers);
    }

    if (!executives.length) {
      infoGlobal.fetchExecutives();
    } else {
      setExecutives(executives);
    }
    setTypeOptions(types);

    // Handle foreign data if buttonAddForeign is true
    if (buttonAddForeign) {
      console.log("organizationForeign", organizationForeign);
      handleForeignData(organizationForeign);
      setFormData((prevState) => ({
        ...prevState,
        organization_id: organizationForeign,
      }));
    }
  }, [organization, insurers, executives, buttonAddForeign, organizationForeign]);

  const handleForeignData = async (organizationForeign) => {
    try {
      if (holders.length < 1) {
        const dataHolders = await getHolderByOrganization(organizationForeign);
        setHolders(dataHolders.holders);
      }
      const dataPools = await getPools();
      setPools(dataPools);
    } catch (error) {
      console.error('Error fetching holders:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Llama a la función para manejar la organización seleccionada
    handleSelectOrganization(name, value);
    const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;

    // Actualiza el estado del formulario con el nuevo valor
    setFormData(prevState => {
      const updatedData = {
        ...prevState,
        [name]: newValue,
      };

      // Calcula la suma total de los campos
      const total = calculateTotalPremium(updatedData);
      console.log("updatedData", updatedData)
      // Calcula el IVA solo si se aplica
      const ivaAmount = parseFloat(updatedData.iva || 0);
      console.log("ivaAmount", ivaAmount)

      // Calcula la prima total
      const totalWithIva = total + ivaAmount;
      console.log("totalWithIva", totalWithIva)

      return {
        ...updatedData,
        total_premium: totalWithIva, // Actualiza la prima total
      };
    });
  };

/*   const handleDateChange = (name, value) => {
    console.log('value', value)

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; */
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleCategoryChange = (index, selectedCategory) => {
    const updatedPdfData = [...pdfData];
    updatedPdfData[index].category = selectedCategory;
    setPdfData(updatedPdfData);
  };

  const handleRemoveFile = (index) => {
    const updatedPdfData = [...pdfData];
    updatedPdfData.splice(index, 1); // Eliminar el archivo en el índice dado
    setPdfData(updatedPdfData);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const updatedPdfData = selectedFiles.map((file) => ({
      file,
    }));

    // Actualizamos el estado con los archivos seleccionados
    setPdfData((prevPdfData) => [...prevPdfData, ...updatedPdfData]);

    // Reseteamos el valor del input
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Limpiar el input de archivos
    }
  };

  const handleSubmit = useCallback(async (event) => {
    const formattedDateStart = format(new Date(formData?.start_date_coverage), 'yyyy-MM-dd');
    const formattedDateEnd = format(new Date(formData?.end_date_coverage), 'yyyy-MM-dd');

    setFormData((prevData) => ({
      ...prevData,
      start_date_coverage: formattedDateStart,
      end_date_coverage: formattedDateEnd,
    }));

    event.preventDefault();
    const form = new FormData();

    // Agregar datos del formulario
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    // Validar archivos PDF dentro del form
    if (pdfData.length === 0) {
      alert("No hay PDFs para subir");
      return;
    }
    console.log("PDFdata", pdfData)

    let hasInvalidCategory = false;
    pdfData.forEach((data) => {
      if (data.category === 'Selecciona' || !data.category) {
        hasInvalidCategory = true; // Marca si hay alguna categoría no seleccionada
      } else {
        form.append(`${data.category}[]`, data.file); // Agrega los archivos con categorías al form
      }
    });

    // Si alguna categoría es inválida, se muestra la alerta y se cancela el envío
    if (hasInvalidCategory) {
      alert("Asegúrate de seleccionar una categoría para todos los archivos.");
      return;
    }
    try {
      await postPolicy(form);
      setButtonAdd(false);
      setFormData(initialPolicyData);
      setPdfData([]);
      if (buttonAddForeign) {
        refresh()
      }
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'La Póliza se ha añadido correctamente.',
      }).then(() => {
        closeModal()
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.response.data.exception} || 'Error desconocido'}</pre>`,

        text: `Hubo un problema al añadir la Póliza. Inténtalo de nuevo. ${error.response.data.exception}`,
      });
    }
  }, [formData, pdfData]);
  const handleSelectOrganization = async (name, value) => {
    if (name === 'organization_id') {
      const dataHolders = await getHolderByOrganization(value);
      setHolders(dataHolders.holders);
      const dataPools = await getPools();
      setPools(dataPools);
    }
  }


  const handleAddPool = (e) => {
    const { value } = e.target;
    if (value === 'addPool') {
      setShowModalPools(true); // Abre el modal de los pools si se selecciona "Añadir Pool"
    } else {
      setFormData({ ...formData, pool: value });
    }

  }
  const handleChangeType = (event) => {
    const selectedName = event.target.value;
    const selectedOption = typeOptions.find(option => option.name === selectedName);
    console.log(selectedOption)
    if (selectedOption) {
      setFormData(prevFormData => ({
        ...prevFormData,
        type: selectedOption.type,
        policy_branch: selectedOption.branch,
        policy_type: selectedOption.name,
        renewable: selectedOption.renewable,
        administration_type: selectedOption.administration_type
      }));
    }
  };

  const handleClose = () => setFileModal(false);
  const handleUpload = (file) => {
    if (file) {
      console.log(file)
      handleClose(); // Cierra el modal después de subir el archivo
    }
  };
  const hanldeExcelChange = (event) => {
    setExcel(event.target.files[0]);
  };
  const calculateTotalPremium = () => {
    const {
      net_premium,
      right_fee,
      financing,
      fractional_payment_surcharge,
      policy_fee,
      bonus1,
      bonus2,
    } = formData;

    // Calcula la suma de los campos
    const total = parseFloat(net_premium || 0) +
      parseFloat(right_fee || 0) +
      parseFloat(financing || 0) +
      parseFloat(fractional_payment_surcharge || 0) +
      parseFloat(policy_fee || 0) +
      parseFloat(bonus1 || 0) +
      parseFloat(bonus2 || 0);

    return total;
  };

  const handleApplyIva = () => {
    // Calcula la suma de todos los campos
    const total = calculateTotalPremium();
    console.log("total", total)
    const ivaPercentage = 0.16;
    const ivaAmount = total * ivaPercentage;
    const calculate = total + ivaAmount
    // Actualiza el estado con el IVA calculado y la prima total
    setFormData({
      ...formData,
      iva: ivaAmount.toFixed(2),
      total_premium: calculate.toFixed(2),
    });
  };

  if (!buttonAdd && !buttonAddForeign) {
    return (
      <div>
        {!buttonAdd && (
          <div>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setButtonAdd(true)}>Nuevo</button>
            </div>
          </div>
        )}
      </div>
    );
  }
  const closeModal = () => {
    if (buttonAddForeign) {
      onClose()
      setFormData(initialPolicyData);
      setPdfData(null)
    } else {
      setButtonAdd(false)
      setFormData(initialPolicyData);
      setPdfData(null)
    }
  }
  const onCloseModal = () => {
    setShowModalPools(false)
    handleForeignData(organizationForeign);
  }
  return (
    <div>
      {showModalPools && (
        <PoolForm
          buttonAddForeign={true}
          onClose={onCloseModal}
        />
      )}
      <Modal
        dialogClassName="modal-xl"
        show={buttonAddForeign || buttonAdd}
        onHide={() => closeModal()}
        style={{ maxWidth: '200%', width: '100%', height: '200vh' }}
      >


        <form onSubmit={handleSubmit} className="container mt-4">

          <Modal.Header closeButton>
            <Modal.Title>Pólizas</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>

            <Row>
              {/* Primera columna - Elementos en fila horizontal */}
              <Col md={12}>
                <div className="d-flex flex-wrap">
                  <Form.Group className="mb-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                    <div className="form-floating" style={{ fontSize: '13px' }}>
                      <select
                        name="organization_id"
                        value={formData.organization_id}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        <option value="">Selecciona una organización</option>
                        {orgDatas.map(org => (
                          <option key={org.id_organization} value={org.id_organization}>
                            {org.id_organization} - {org.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="organization_id">Organización</label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 mx-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                    <div className="form-floating" style={{ fontSize: '13px' }}>
                      <select
                        name="policy_holder_id"
                        value={formData.policy_holder_id}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        <option value="">Selecciona un Contratante</option>
                        {holders.map(holder => (
                          <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                            {holder.id_policy_holder} - {holder.business_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="policy_holder_id">Contratante</label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                    <div className="form-floating" style={{ fontSize: '13px' }}>
                      <select
                        name="insurer_name"
                        value={formData.insurer_name}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        <option value="">Selecciona una Aseguradora</option>
                        {insurersDatas.map(insurer => (
                          <option key={insurer.id_insurer} value={insurer.commercial_name}>
                            {insurer.id_insurer} - {insurer.commercial_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="insurer_name">Aseguradora</label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 mx-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                    <div className="form-floating" style={{ fontSize: '13px' }}>
                      <select
                        name="executive_name"
                        value={formData.executive_name}
                        onChange={handleChange}
                        className="form-control"
                        style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                      >
                        <option value="">Selecciona un Ejecutivo</option>
                        {executivesDatas.map(executive => (
                          <option key={executive.id_executive} value={executive.name}>
                            {executive.id_executive} - {executive.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="executive_name">Ejecutivo</label>
                    </div>
                  </Form.Group>
                </div>

              </Col>
            </Row>

            {/* Segunda columna - Elementos en un acordeón */}
            <hr />
            <Accordion defaultActiveKey="1" className="mb-3">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Datos de Póliza</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <input
                            type="text"
                            name="policy_number"
                            value={formData.policy_number}
                            onChange={handleChange}
                            className="form-control"
                            id="policy_number"
                            placeholder="Número de Póliza"
                            style={{ fontSize: 'inherit' }}
                          />
                          <label htmlFor="policy_number" style={{ fontSize: 'inherit' }}>
                            Número de Póliza
                          </label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="mb-3">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="policy_type"
                            onChange={handleChangeType}
                            value={formData.policy_type}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}
                          >
                            <option value="">Selecciona un tipo de póliza</option>
                            {typeOptions.map(option => (
                              <option key={option.name} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                          <label htmlFor="policy_type">Tipo de Póliza</label>
                        </div>
                      </Form.Group>
                    </Col>
                    {formData.type !== 'LC' && (
                      <Col md={2}>
                        <Form.Group className="">
                          <div className="form-floating" style={{ fontSize: '13px' }}>
                            <input
                              style={{ fontSize: 'inherit' }}
                              type="text"
                              name="policy_holder"
                              value={formData.policy_holder}
                              onChange={handleChange}
                              className="form-control"
                              id="policy_holder"
                              placeholder="Titular de la Póliza"
                            />
                            <label htmlFor="policy_holder">Titular de la Póliza</label>
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="type"
                            onChange={handleChange}
                            value={formData.type}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}
                          >
                            <option value="">Linea del Tipo de Póliza</option>
                            <option value="LC">Líneas Comerciales</option>
                            <option value="LP">Líneas Personales</option>
                          </select>
                          <label htmlFor="type">Linea del Tipo de Póliza</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="policy_branch"
                            value={formData.policy_branch}
                            onChange={handleChange}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}

                          >
                            <option value="">Selecciona una Rama</option>
                            <option value="GMM">GMM</option>
                            <option value="Auto">Auto</option>
                            <option value="Casa">Casa</option>
                            <option value="Daños">Daños</option>
                          </select>
                          <label htmlFor="policy_branch">Rama</label>
                        </div>
                      </Form.Group>
                    </Col>


                    <Col md={2}>
                      <Form.Group>
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="pool"
                            onChange={handleAddPool}
                            value={formData.pool}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}
                          >
                            <option value="">Selecciona una pool</option>
                            {pools.map(pool => (
                              <option key={pool.id} value={pool.id}>{pool.name}</option>
                            ))}
                            <option value="addPool">➕ Añadir Pool</option> {/* Opción para añadir pool */}
                          </select>
                          <label htmlFor="pool">Pool</label>
                        </div>
                      </Form.Group>

                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      {/*  <DateField
                        label="Inicio Cobertura"
                        name="start_date_coverage"
                        value={formData.start_date_coverage}
                        onChange={handleDateChange}
                      /> */}
                      <Form.Group className="mb-3">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <input
                            style={{ fontSize: 'inherit' }}
                            type="date"
                            name="start_date_coverage"
                            className="form-control"
                            value={formData.start_date_coverage}
                            onChange={handleDateChange}
                            placeholder="dd/mm/yyyy" // Indica el formato esperado
                            id="start_date_coverage"
                          />
                          <label htmlFor="start_date_coverage">Inicio de Cobertura</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      {/* <DateField
                        label="Fin Cobertura"
                        name="end_date_coverage"
                        value={formData.end_date_coverage}
                        onChange={handleDateChange}
                      /> */}
                      <Form.Group className="mb-3">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <Form.Label htmlFor="end_date_coverage"></Form.Label>
                          <input
                            style={{ fontSize: 'inherit' }}
                            type="date"
                            name="end_date_coverage"
                            className="form-control"
                            value={formData.end_date_coverage}
                            onChange={handleDateChange}
                            placeholder="DD/MM/YYYY" // Indica el formato esperado
                            id="start_date_coverage"
                            pattern="\d{2}/\d{2}/\d{4}" // Validación de patrón
                          />
                          <label htmlFor="end_date_coverage">Fin de Cobertura</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="renewable"
                            onChange={handleChange}
                            value={formData.renewable}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}
                          >
                            <option value="">Selecciona si es Renovable</option>
                            <option value="Renovable">Renovable</option>
                            <option value="No Renovable">No Renovable</option>
                          </select>
                          <label htmlFor="renewable">Póliza Renovable</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <select
                            name="administration_type"
                            onChange={handleChange}
                            value={formData.administration_type}
                            className="form-select"
                            style={{ fontSize: 'inherit' }}
                          >
                            <option value="">Tipo de Administracion</option>
                            <option value="Auto Administrada">Auto Administrada</option>
                            <option value="A Detalle">A Detalle</option>
                            <option value="A Pronostico">A Pronostico</option>
                            <option value="A Declaracion">A Declaracion</option>

                          </select>
                          <label htmlFor="administration_type">Tipo de Administracion</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <input
                            style={{ fontSize: 'inherit' }}
                            type="email"
                            name="admin_email"
                            value={formData.admin_email}
                            onChange={handleChange}
                            className="form-control"
                            id="admin_email"
                            placeholder="Admin de la Póliza"
                          />
                          <label htmlFor="admin_email">Admin de la Póliza</label>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group className="">
                        <div className="form-floating" style={{ fontSize: '13px' }}>
                          <input
                            style={{ fontSize: 'inherit' }}
                            type="text"
                            name="concept"
                            value={formData.concept}
                            onChange={handleChange}
                            className="form-control"
                            id="concept"
                            placeholder="Concepto"
                          />
                          <label htmlFor="concept">Concepto</label>
                        </div>
                      </Form.Group>

                    </Col>
                  </Row>

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Tercera columna - Elementos en un tercer acordeón */}
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="2">
                <Accordion.Header>Primas</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="row g-2">
                    <div className="col-md-2 form-floating">
                      <select
                        name="currency"
                        onChange={handleChange}
                        value={formData.currency}
                        className="form-select"
                        style={{ fontSize: '13px' }}
                      >
                        <option value="">Selecciona la Moneda</option>
                        <option value="Pesos">Pesos</option>
                        <option value="Dolares">Dolares</option>
                        <option value="Euros">Euros</option>
                      </select>
                      <label htmlFor="currency">Moneda</label>
                    </div>

                    {/* Campo de Prima Neta */}
                    <div className="col-md-2 form-floating">
                      <input
                        type="number"
                        name="net_premium"
                        value={formData.net_premium}
                        onChange={handleChange}
                        id="net_premium"
                        className="form-control"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="net_premium">Prima Neta</label>
                    </div>

                    {/* Campo de Derecho */}
                    <div className="col-md-2 form-floating">
                      <input
                        type="number"
                        name="right_fee"
                        value={formData.right_fee}
                        onChange={handleChange}
                        id="right_fee"
                        className="form-control"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="right_fee">Derecho de Póliza</label>
                    </div>



                    <div className="col-md-2 form-floating">
                      <input
                        type="number"
                        name="fractional_payment_surcharge"
                        value={formData.fractional_payment_surcharge}
                        onChange={handleChange}
                        className="form-control"
                        id="fractional_payment_surcharge"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="fractional_payment_surcharge">Recargo por Pago Fraccionado</label>
                    </div>

                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="policy_fee"
                        value={formData.policy_fee}
                        onChange={handleChange}
                        id="policy_fee"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="policy_fee">Cuota de Póliza</label>
                    </div>


                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="text"
                        name="payment_method"
                        value={formData.payment_method}
                        onChange={handleChange}
                        id="payment_method"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="payment_method">Método de Pago</label>
                    </div>

                    {/* Campo de IVA con botón de Aplicar */}
                    <div className="col-md-2 form-floating">
                      <input
                        type="number"
                        name="iva"
                        value={formData.iva}
                        onChange={handleChange}
                        id="iva"
                        className="form-control"
                        style={{ fontSize: '13px' }}
                        readOnly
                      />
                      <label htmlFor="iva">IVA</label>
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={handleApplyIva}
                        style={{
                          position: '',
                          top: '-10px',
                          right: '1px',
                          zIndex: 1,
                          fontSize: '10px',
                          backgroundColor: 'transparent',
                          border: '1px solid #007bff', // Color de borde (ajústalo según tu preferencia)
                          color: '#007bff', // Color del texto (ajústalo según tu preferencia)
                        }}
                      >
                        Aplicar IVA
                      </button>
                    </div>
                    {/* Campo de Prima Total */}
                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="total_premium"
                        value={formData.total_premium}
                        id="total_premium"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="total_premium">Prima Total</label>
                    </div>

                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* Tercera columna - Elementos en un tercer acordeón */}
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="2">
                <Accordion.Header>Comisiones</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="row g-2">
                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="commission_percentage"
                        value={formData.commission_percentage}
                        onChange={handleChange}
                        id="commission_percentage"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="commission_percentage">Porcentaje de Comisión</label>
                    </div>

                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="bonus1"
                        value={formData.bonus1}
                        onChange={handleChange}
                        id="bonus1"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="bonus1">Bono 1</label>
                    </div>

                    <div className="col-md-2 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="bonus2"
                        value={formData.bonus2}
                        onChange={handleChange}
                        id="bonus2"
                        style={{ fontSize: '13px' }}
                      />
                      <label htmlFor="bonus2">Bono 2</label>
                    </div>
                    <div className="col-md-3 form-floating">
                      <input
                        className="form-control"
                        type="number"
                        name="total_premium"
                        value={formData.total_premium}
                        id="total_premium"
                        style={{ fontSize: '13px' }}
                        disabled
                      />
                      <label htmlFor="total_premium">Prima Total (Como referencia)</label>
                    </div>
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* Archivos PDF */}
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  {/* <RenderDropzone
                  onFilesChange={handleFileChange}
                  fileName="Poliza PDF"
                  category="Policy"
                  files={formData.pdfs}
                /> */}
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Archivos</Accordion.Header>
                      <Accordion.Body>

                        <input
                          ref={fileInputRef}
                          class="form-control"
                          type="file"
                          accept=".pdf"
                          multiple
                          onChange={handleFileChange}
                        />
                        <br />
                        {pdfData && (
                          <div>
                            {pdfData?.map((pdf, index) => (
                              <div key={index} style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* Texto */}
                                  <p style={{
                                    margin: 0,
                                    marginRight: '10px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '90%'
                                  }}>
                                    {pdf.file.name}
                                  </p>
                                  {/* Select */}
                                  <select
                                    value={pdf.category}
                                    className="form-select"
                                    style={{ marginRight: '10px' }}
                                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                                  >
                                    <option value="">Selecciona</option>
                                    {Object.entries(categories).map(([key, value], i) => (
                                      <option key={i} value={key}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>

                                  {/* Botón */}
                                  <button
                                    type="button"
                                    className='btn btn-danger'
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    Eliminar
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </Form.Group>


              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-danger" onClick={() => closeModal()}>Cancelar</button>
            <button type="submit" className="btn btn-primary me-3">Guardar</button>
          </Modal.Footer>
        </form>

      </Modal >
    </div>
  );

};

export default PolicyForm;

