import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Form, Col, Row, Accordion } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { getHolderByOrganization } from '../../api/PolicyHolderService';
import { getPools } from '../../features/pools/poolService';
import { infoGlobal } from '../../global';
import { types } from '../Helpers/policyData';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updatePolicy } from '../../api/policyService';
const MySwal = withReactContent(Swal);
const ModalEditPolicie = ({ data, showModal, closeModal }) => {
    // const data = [
    //     {
    //         policy_number: 'POL123456',
    //         policy_holder: 'Juan Pérez',
    //         policy_branch: 'Sucursal Central',
    //         concept: 'Seguro de Vida',
    //         start_date_coverage: '2023-01-01',
    //         end_date_coverage: '2024-01-01',
    //         total: '1000',
    //         pool: 'Pool A',
    //         policy_type: 'GMM Colectivo',
    //         policy_branch: 'GMM',
    //         executive_name: 'Ana Gómez',
    //         admin_email: 'admin@example.com',
    //         insurer_name: 'Seguros XYZ',
    //         payment_method: 'Transferencia Bancaria',
    //         policy_holder_id: '1',
    //         organization_id: '1',
    //         insurer_name: 'GNP Seguros',
    //         executive_name: 'Jorge Ortega',
    //         renewable: 'Renovable',
    //         administration_type: "A Detalle",
    //         pool: "1",
    //     },
    // ]
    const [formData, setFormData] = useState({})
    const organization = useSelector((state) => state.organizations.data);
    const insurers = useSelector((state) => state.insurer.data);
    const executives = useSelector((state) => state.executive.data);
    const [insurersDatas, setInsurers] = useState([]);
    const [orgDatas, setOrgDatas] = useState([]);
    const [executivesDatas, setExecutives] = useState([]);
    const [holders, setHolders] = useState([]);
    const [pools, setPools] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [showTypeOptions, setShowTypeOptions] = useState(false);
    const [showModalPools, setShowModalPools] = useState(false);
    const [edit, setEdit] = useState(false);
    
    useEffect(() => {
        console.log(formData)
        const { policy_holder, ...newData } = data; // Desestructuramos y excluimos policy_holder
        setFormData(newData);
        selectData(data.organization_id);
    }, []);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

    };
    const handleSelectOrganization = async (name, value) => {
        if (name === 'organization_id') {
            const dataHolders = await getHolderByOrganization(value);
            console.log("dataHolders", dataHolders)
            setHolders(dataHolders.holders);
            const dataPools = await getPools();
            setPools(dataPools);
        }
        setFormData({ ...formData, [name]: value });
    }
    const selectData = async (organizationId) => {
        try {
            const dataHolders = await getHolderByOrganization(organizationId);
            setHolders(dataHolders.holders);
            console.log("Prueba 88", organizationId)
            setFormData(prevState => ({
                ...prevState,
                ["id_policy_holder"]: data.policy_holder_id,
                ['insurer_name']: data.insurer.commercial_name,
                ['executive_name']: data.executive.name,
                ['pool']: data.pool.id,
            }));
            const dataPools = await getPools();
            setPools(dataPools);

        } catch (error) {
            console.error('Error fetching holders:', error);
        }
    };
    useEffect(() => {
        console.log("Datos desde modalEdit", data)
        if (!organization.length) {
            infoGlobal.fetchOrganizations();
        } else {
            setOrgDatas(organization);
        }

        if (!insurers.length) {
            infoGlobal.fetchInsurers();
        } else {
            setInsurers(insurers);
        }

        if (!executives.length) {
            infoGlobal.fetchExecutives();
        } else {
            setExecutives(executives);
        }
        setTypeOptions(types);

    }, [organization, insurers, executives]);


    const handleDateChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleAddPool = (e) => {
        const { value } = e.target;
        if (value === 'addPool') {
            setShowModalPools(true); // Abre el modal de los pools si se selecciona "Añadir Pool"
        } else {
            setFormData({ ...formData, pool: value });
        }

    }
    const handleChangeType = (event) => {
        const selectedName = event.target.value;
        const selectedOption = typeOptions.find(option => option.name === selectedName);
        console.log(selectedOption)
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                type: selectedOption.type,
                policy_branch: selectedOption.branch,
                policy_type: selectedOption.name
            }));
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            await updatePolicy(data.id_policy, formData);

            MySwal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Los datos se han actualizado correctamente.',
            });
            setEdit(false);
            closeModal()

        } catch (error) {
            console.error('Error updating Policy:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar los datos.',
            });
        }
    }
    return (
        <div>
            <Modal
                dialogClassName="modal-xl"
                show={showModal}
                style={{ maxWidth: '200%', width: '100%', height: '200vh' }}
                onHide={() => closeModal()}

            >

                <form onSubmit={handleSubmit} className="container mt-4">

                    <Modal.Header closeButton>
                        <Modal.Title>Editar Póliza {data.policy_number}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>

                        <Row>
                            {/* Primera columna - Elementos en fila horizontal */}
                            <Col md={12}>
                                <div className="d-flex flex-wrap">
                                    <Form.Group className="mb-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                                        <div className="form-floating" style={{ fontSize: '13px' }}>
                                            <select
                                                name="organization_id"
                                                value={formData.organization_id || ''}
                                                onChange={handleSelectOrganization}
                                                className="form-control"
                                                style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                disabled
                                            >
                                                <option value="">Selecciona una organización</option>
                                                {orgDatas.map(org => (
                                                    <option key={org.id_organization} value={org.id_organization}>
                                                        {org.id_organization} - {org.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="organization_id">Organización</label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3 mx-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                                        <div className="form-floating" style={{ fontSize: '13px' }}>
                                            <select
                                                name="policy_holder_id"
                                                value={formData.policy_holder_id || ''}
                                                onChange={handleChange}
                                                className="form-control"
                                                disabled
                                                style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                            >
                                                <option value="">Selecciona un Contratante</option>
                                                {holders.map(holder => (
                                                    <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                                                        {holder.id_policy_holder} - {holder.business_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="policy_holder_id">Contratante</label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                                        <div className="form-floating" style={{ fontSize: '13px' }}>
                                            <select
                                                name="insurer_name"
                                                value={formData.insurer_name || ''}
                                                onChange={handleChange}
                                                className="form-control"
                                                disabled
                                                style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                            >
                                                <option value="">Selecciona una Aseguradora</option>
                                                {insurersDatas.map(insurer => (
                                                    <option key={insurer.id_insurer} value={insurer.commercial_name}>
                                                        {insurer.id_insurer} - {insurer.commercial_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="insurer_name">Aseguradora</label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3 mx-3" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                                        <div className="form-floating" style={{ fontSize: '13px' }}>
                                            <select
                                                name="executive_name"
                                                value={formData.executive_name || ''}
                                                onChange={handleChange}
                                                className="form-control"
                                                disabled
                                                style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                            >
                                                <option value="">Selecciona un Ejecutivo</option>
                                                {executivesDatas.map(executive => (
                                                    <option key={executive.id_executive} value={executive.name}>
                                                        {executive.id_executive} - {executive.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="executive_name">Ejecutivo</label>
                                        </div>
                                    </Form.Group>
                                </div>

                            </Col>
                        </Row>

                        <hr />
                        <Accordion defaultActiveKey="1" className="mb-3">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Datos de Póliza</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <input
                                                        type="text"
                                                        name="policy_number"
                                                        value={formData.policy_number}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="policy_number"
                                                        placeholder="Número de Póliza"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    />
                                                    <label htmlFor="policy_number" style={{ fontSize: 'inherit' }}>
                                                        Número de Póliza
                                                    </label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="policy_type"
                                                        onChange={handleChangeType}
                                                        value={formData.policy_type || ''}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    >
                                                        <option value="">Selecciona un tipo de Póliza</option>
                                                        {typeOptions.map(option => (
                                                            <option key={option.name} value={option.name}>{option.name}</option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="policy_type">Tipo de Póliza</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {formData.type !== 'LC' && (
                                            <Col md={2}>
                                                <Form.Group className="">
                                                    <div className="form-floating" style={{ fontSize: '13px' }}>
                                                        <input
                                                            style={{ fontSize: 'inherit' }}
                                                            type="text"
                                                            name="holder_name"
                                                            value={formData.holder_name || ''}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="holder_name"
                                                            disabled={!edit}
                                                            placeholder="Titular de la Póliza"
                                                        />
                                                        <label htmlFor="holder_name">Titular de la Póliza</label>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )}
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="type"
                                                        onChange={handleChange}
                                                        value={formData.type || ''}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    >
                                                        <option value="">Linea del Tipo de Póliza</option>
                                                        <option value="LC">Líneas Comerciales</option>
                                                        <option value="LP">Líneas Personales</option>
                                                    </select>
                                                    <label htmlFor="type">Linea del Tipo de Póliza</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="policy_branch"
                                                        value={formData.policy_branch || ''}
                                                        onChange={handleChange}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}

                                                    >
                                                        <option value="">Selecciona una Rama</option>
                                                        <option value="GMM">GMM</option>
                                                        <option value="Auto">Auto</option>
                                                        <option value="Casa">Casa</option>
                                                        <option value="Daños">Daños</option>
                                                    </select>
                                                    <label htmlFor="policy_branch">Rama</label>
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col md={2}>
                                            <Form.Group>
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="pool"
                                                        onChange={handleAddPool}
                                                        value={formData.pool || ''}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    >
                                                        <option value="">Selecciona una pool</option>
                                                        {pools.map(pool => (
                                                            <option key={pool.id} value={pool.id}>{pool.name}</option>
                                                        ))}
                                                        <option value="addPool">➕ Añadir Pool</option> {/* Opción para añadir pool */}
                                                    </select>
                                                    <label htmlFor="pool">Pool</label>
                                                </div>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className="mb-3">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <input
                                                        style={{ fontSize: 'inherit' }}
                                                        type="date"
                                                        name="start_date_coverage"
                                                        className="form-control"
                                                        value={formData.start_date_coverage || ''}
                                                        onChange={handleDateChange}
                                                        placeholder="DD/MM/YYYY" // Indica el formato esperado
                                                        id="start_date_coverage"
                                                        pattern="\d{2}/\d{2}/\d{4}"
                                                        disabled={!edit}

                                                    />
                                                    <label htmlFor="start_date_coverage">Inicio de Cobertura</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <Form.Label htmlFor="end_date_coverage"></Form.Label>
                                                    <input
                                                        style={{ fontSize: 'inherit' }}
                                                        type="date"
                                                        name="end_date_coverage"
                                                        className="form-control"
                                                        value={formData.end_date_coverage || ''}
                                                        onChange={handleDateChange}
                                                        placeholder="DD/MM/YYYY" // Indica el formato esperado
                                                        id="start_date_coverage"
                                                        pattern="\d{2}/\d{2}/\d{4}"
                                                        disabled={!edit}

                                                    />
                                                    <label htmlFor="end_date_coverage">Fin de Cobertura</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="renewable"
                                                        onChange={handleChange}
                                                        value={formData.renewable || ''}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    >
                                                        <option value="">Selecciona si es Renovable</option>
                                                        <option value="Renovable">Renovable</option>
                                                        <option value="No Renovable">No Renovable</option>
                                                    </select>
                                                    <label htmlFor="renewable">Póliza Renovable</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <select
                                                        name="administration_type"
                                                        onChange={handleChange}
                                                        value={formData.administration_type || ''}
                                                        className="form-select"
                                                        disabled={!edit}
                                                        style={{ fontSize: 'inherit' }}
                                                    >
                                                        <option value="">Tipo de Administracion</option>
                                                        <option value="Auto Administrada">Auto Administrada</option>
                                                        <option value="A Detalle">A Detalle</option>
                                                        <option value="A Pronostico">A Pronostico</option>
                                                        <option value="A Declaracion">A Declaracion</option>

                                                    </select>
                                                    <label htmlFor="administration_type">Póliza Renovable</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <input
                                                        style={{ fontSize: 'inherit' }}
                                                        type="email"
                                                        name="admin_email"
                                                        value={formData.admin_email || ''}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="admin_email"
                                                        disabled={!edit}
                                                        placeholder="Admin de la Póliza"
                                                    />
                                                    <label htmlFor="admin_email">Admin de la Póliza</label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="">
                                                <div className="form-floating" style={{ fontSize: '13px' }}>
                                                    <input
                                                        style={{ fontSize: 'inherit' }}
                                                        type="text"
                                                        name="concept"
                                                        value={formData.concept || ''}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        id="concept"
                                                        disabled={!edit}
                                                        placeholder="Concepto"
                                                    />
                                                    <label htmlFor="concept">Concepto</label>
                                                </div>
                                            </Form.Group>

                                        </Col>
                                    </Row>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="btn btn-primary me-3"
                            onClick={() => setEdit(prevEdit => !prevEdit)} // Alternar el estado
                        >
                            {edit ? 'Cancelar' : 'Editar'}
                        </Button>
                        <button type="submit" className="btn btn-primary me-3">Actualizar</button>
                    </Modal.Footer>
                </form>

            </Modal >
        </div>
    )
};

export default ModalEditPolicie;
