import React, { useState, useEffect } from 'react';
import { postSubgroup } from '../api/SubgroupService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getPoliciesByHolder } from '../api/policyService';
import { getHolderByOrganization } from '../api/PolicyHolderService';
import { useDispatch, useSelector } from 'react-redux';
import { infoGlobal } from '../global';
import { Modal, Button, Form } from 'react-bootstrap';
import { RenderDropzone } from '../features/pdfs/functionsPdf';

const MySwal = withReactContent(Swal);

function SubgroupForm({ buttonAddForeign, organizationForeign, policyHolderForeign, policyForeign, onClose, onUpdate }) {
  const dispatch = useDispatch();

  const [botonAdd, setBotonAdd] = useState(false);
  const [formData, setFormData] = useState({ subgroup_number: '', subgroup_name: '', policy_id: '', pdfs: [] });
  const [policies, setPolicies] = useState([]);
  const [holders, setHolders] = useState([]);
  const [selectedHolder, setSelectedHolder] = useState('');
  const [orgDatas, setOrgDatas] = useState([]);
  const organization = useSelector((state) => state.organizations.data);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization);
    }
    if (buttonAddForeign) {
      setBotonAdd(true);
      handleForeignData(organizationForeign, policyHolderForeign, policyForeign);
      setSelectedHolder(policyHolderForeign);
      setFormData((prevState) => ({
        ...prevState,
        organization_id: organizationForeign,
        policy_id: policyForeign,
      }));
    }
  }, [organization, buttonAddForeign, organizationForeign, policyHolderForeign, policyForeign, infoGlobal]);

  const handleForeignData = async (organizationForeign, policyHolderForeign, policyForeign) => {
    const dataHolders = await getHolderByOrganization(organizationForeign);
    setHolders(dataHolders.holders);
    if (policyHolderForeign) {
      const dataPolicies = await getPoliciesByHolder(policyHolderForeign);
      setPolicies(dataPolicies);
    }
  }

  const closeModal = () => {
    if (onClose) {
      onClose();
    } else {
      setBotonAdd(false);
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    handleSelectOrganization(name, value);
  };

  const handleHolderChange = (event) => {
    const holderId = event.target.value;
    setSelectedHolder(holderId);
    fetchPolicies(holderId);
  };

  const handleFileChange = (updatedFiles, category) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      pdfs: updatedFiles
    }));
    console.log(category, updatedFiles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await postSubgroup(formData);
      if (onClose) onClose(); // Llama a la función de cierre del modal
      if (onUpdate) onUpdate();
      setFormData({ subgroup_number: '', subgroup_name: '', policy_id: '', pdfs: [] });
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'El subgrupo se ha añadido correctamente.',
      });
      setBotonAdd(false); // Después de enviar el formulario, cambia el estado de botonAdd a false
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Hubo un problema al añadir el Subgrupo. Inténtalo de nuevo.',
      });
    }
  };

  const fetchPolicies = async (policy_holder_id) => {
    try {
      const response = await getPoliciesByHolder(policy_holder_id);
      const data = response;
      setPolicies(data);
    } catch (error) {
      console.error('Error fetching Policies:', error);
    }
  };

  const handleSelectOrganization = async (name, value) => {
    if (name === 'organization_id') {
      const dataHolders = await getHolderByOrganization(value);
      setHolders(dataHolders.holders);
    }
  }
  if (!botonAdd) {
    return (
      <div>
        {!botonAdd && (
          <div>
            <h2> Subgrupos</h2>
            <div className="d-flex justify-content-end mb-3">
              <button className="btn btn-success w-100" onClick={() => setBotonAdd(true)}>Nuevo</button>

            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Modal dialogClassName="modal-xl" show={true} onHide={() => closeModal()}>
      <form onSubmit={handleSubmit} className="container mt-4">
        <Modal.Header closeButton>
          <Modal.Title>Subgrupos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <div className="form-floating">
                  <select
                    name="organization_id"
                    value={formData.organization_id}
                    onChange={handleChange}
                    className="form-select"
                    id="floatingOrgSelect"
                  >
                    <option value="">Selecciona una organización</option>
                    {orgDatas.map(org => (
                      <option key={org.id_organization} value={org.id_organization}>
                        {org.id_organization} - {org.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingOrgSelect">Organización</label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <div className="form-floating">
                  <select
                    name="policy_holder_id"
                    value={selectedHolder}
                    onChange={handleHolderChange}
                    className="form-select"
                    id="floatingHolderSelect"
                  >
                    <option value="">Selecciona un Contratante</option>
                    {holders.map(holder => (
                      <option key={holder.id_policy_holder} value={holder.id_policy_holder}>{holder.business_name}</option>
                    ))}
                  </select>
                  <label htmlFor="floatingHolderSelect">Contratante</label>
                </div>
              </div>
            </div>
          </div>
          {selectedHolder && (
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      name="policy_id"
                      value={formData.policy_id}
                      onChange={handleChange}
                      className="form-select"
                      id="floatingPolicySelect"
                    >
                      <option value="">Selecciona una Póliza</option>
                      {policies.map(policy => (
                        <option key={policy.id_policy} value={policy.id_policy}>{policy.policy_number}</option>
                      ))}
                    </select>
                    <label htmlFor="floatingPolicySelect">Póliza</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      name="subgroup_number"
                      value={formData.subgroup_number}
                      onChange={handleChange}
                      className="form-control"
                      id="floatingSubgroupNumber"
                      placeholder=" "
                    />
                    <label htmlFor="floatingSubgroupNumber">Numero del Subgrupo</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      name="subgroup_name"
                      value={formData.subgroup_name}
                      onChange={handleChange}
                      className="form-control"
                      id="floatingSubgroupName"
                      placeholder=" "
                    />
                    <label htmlFor="floatingSubgroupName">Nombre del Subgrupo</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      name="hospital_level"
                      value={formData.hospital_level}
                      onChange={handleChange}
                      className="form-control"
                      id="floatingHospitalLevel"
                      placeholder=" "
                    />
                    <label htmlFor="floatingHospitalLevel">Nivel Hospitalario</label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="pdf_files" className="form-label">Presentacion</label>
                  <RenderDropzone
                    onFilesChange={handleFileChange}
                    fileName="Presentacion"
                    category="Presentacion"
                    files={formData.pdfs}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-danger" onClick={() => closeModal()}>Cancelar</button>
          <button type="submit" className="btn btn-primary me-3">Guardar</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default SubgroupForm;
