import React, { useState, useEffect } from 'react';
import { getHolders, updateHolder, deleteHolder, getHolderByOrganization } from '../api/PolicyHolderService';
import { getPoliciesByHolder } from '../api/policyService';
import { getSubgroupByPolicyId } from "../api/SubgroupService";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PolicyHolderForm from './PolicyHolderForm';
import { pdfLogo } from "../global";
import CertificatesForm from './CertificatesForm';
import { deleteCertificate, getCertificatesBySubgroup, updateCertificate } from '../api/CertificateService';
import { getOrganizations } from '../api/OrganizationService';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { deletePDF } from '../api/PdfService';
import { getInsuredsByCertificate } from '../api/InsuredService';

const MySwal = withReactContent(Swal);

function InsuredList() {
  const { getRootProps, getInputProps, isDragActive } = useDropzone();

  const [holders, setHolders] = useState([]); // Estado para almacenar los contratantes
  const [policies, setPolicies] = useState([]); // Estado para almacenar las pólizas
  const [subgroups, setSubgroups] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [insureds, setInsureds] = useState([]);
  const [users, setUsers] = useState([]);
  const [certificateInsured, setCertificateInsured] = useState([]);


  const [updatedData, setUpdatedData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [selectedSubgroup, setSelectedSubgroup] = useState('');
  const [selectedCertificate, setSelectedCertificate] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const orgData = await getOrganizations();
      setOrganizations(orgData.organizations);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };
  const handleOrganizationChange = async (event) => {
    try {
      const selectedOrgId = event.target.value;
      console.log("selectedOrgId", selectedOrgId);
      setSelectedOrganization(selectedOrgId);
      //obtener los policyHOLder dado el id de la organizacion
      const data = await getHolderByOrganization(selectedOrgId);
      setHolders(data.holders);
    } catch (error) {
      console.error('Error handling organization change:', error);
    }
  };
  const handlePolicyHolderChange = async (event) => {
    try {
      const selectedPolicyHolder = event.target.value;
      console.log("selectedPolicyHolder", selectedPolicyHolder);
      setSelectedPolicyHolder(selectedPolicyHolder);
      //obtener las polizas dado el id del policy holder
      const data = await getPoliciesByHolder(selectedPolicyHolder);
      console.log("Data de getPoliciesByHolder", data)
      setPolicies(data);
    } catch (error) {
      console.error('Error handling policyHolder change:', error);
    }
  };
  const handlePolicyChange = async (event) => {
    setSelectedPolicy(event.target.value);
    if (event.target.value != '') {
      try {
        const dataSubgroups = await getSubgroupByPolicyId(event.target.value);
        console.log("Data sub", dataSubgroups);
        setSubgroups(dataSubgroups);
      } catch (error) {
        console.error('Error fetching subgroups:', error);
      }
    }
  };
  const handleSubgorupChange = async (event) => {
    try {
      const selectedSubgroup = event.target.value;
      console.log("selectedSubgroup", selectedSubgroup);
      setSelectedSubgroup(selectedSubgroup);
      //obtener las polizas dado el id del policy holder
      const data = await getCertificatesBySubgroup(selectedSubgroup);
      setCertificates(data);
    } catch (error) {
      console.error('Error handling subgroup change:', error);
    }
  };
  const handleCertificateChange = async (event) => {
    try {
      const selectedCertificate = event.target.value;
      console.log("selectedCertificate", selectedCertificate);
      setSelectedCertificate(selectedCertificate);

      // Obtener los asegurados y el certificado dado el id del certificado
      const response = await getInsuredsByCertificate(selectedCertificate);
      console.log("response", response.insureds)

      // Setear los datos obtenidos en el estado
      setInsureds(response.insureds);
      setCertificateInsured(response.certificate);
      setDataFetched(true);

      console.log("Obtener Asegurado y usuarios dado el certificado", response);
    } catch (error) {
      console.error('Error handling Certificate change:', error);
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const certificateToEdit = certificates.find((certificate) => certificate.id_certificate === id);
    setFormData({ ...certificateToEdit });
    setShowModal(true);
  };
  const openPdf = (pdfUrl) => {
    const newWindow = window.open(pdfUrl);
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.fetch(pdfUrl, {
        });
      };
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setUpdatedData({});
  };
  const handleEditSecundary = () => {
    setEdit(true);
  };

  const handleSaveEdit = async () => {
    try {
      console.log("edditingID", editingId)
      await updateCertificate(editingId, updatedData);
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(fetchInsureds);
      setShowModal(false);
      setEdit(false);

    } catch (error) {
      console.error('Error updating subGroup:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar el Certificado?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCertificate(id);
          setCertificates(prev => prev.filter(certificates => certificates.id_certificate !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El certificado se ha eliminado correctamente.',
          }).then(fetchInsureds);
        } catch (error) {
          console.error('Error deleting certificates:', error.response.data.message);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            html: `Ha ocurrido un error al eliminar El certificado.<br/><strong>${error.response.data.message}</strong>`,
          });

        }
      }
    });
  };

  const fetchInsureds = async () => {
    if (subgroups) {
      try {
        const data = await getInsuredsByCertificate(selectedCertificate);
        console.log("DATAAA", data)
        setInsureds(data.insureds);
        setUsers(data.users)
      } catch (error) {
        console.error('Error fetching Insureds:', error);
      }
    }
  };

  const handleClose = async () => {
    setShowModal(false)
  }
  const handleFieldChange = (fieldName, value) => {
    setUpdatedData((prevData) => ({ ...prevData, [fieldName]: value }));
  };
  const handleDeletePDF = async (id, pdfId) => {
    try {
      await deletePDF(pdfId);
      console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
      alert("anadir el recargar las polizas")
    } catch (error) {
      console.error('Error deleting PDF:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al eliminar el PDF.',
      });
    }
  };
  return (
    <div>
      <div className="w-100 px-3 mx-auto">
        <div className="row">
          <div className="col-md-6">
            <label>
              Asegurados
            </label>
            <div className="mb-3">
              <select
                name="organization_id"
                value={selectedOrganization}
                onChange={handleOrganizationChange}
                className="form-control"
              >
                <option value="">Selecciona una Organización</option>
                {organizations.map((org) => (
                  <option key={org.id_organization} value={org.id_organization}>
                    {org.id_organization} - {org.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <label>
              Contratante
            </label>
            <div className="mb-3">
              <select
                name="PolicyHolder_id"
                value={selectedPolicyHolder}
                onChange={handlePolicyHolderChange}
                className="form-control"
              >
                <option value="">Selecciona un Contratante</option>
                {holders.map((holder) => (
                  <option key={holder.id_policy_holder} value={holder.id_policy_holder}>
                    {holder.id_policy_holder} - {holder.business_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <label>
              Póliza
            </label>
            <div className="col-md-6">
              <select
                value={selectedPolicy}
                onChange={handlePolicyChange}
                className="form-control"
              >
                <option value="">Selecciona una Póliza</option>
                {policies.map(policy => (
                  <option key={policy.id_policy} value={policy.id_policy}>
                    {policy.policy_number}
                  </option>
                ))}
              </select>
            </div>

          </div>
          <div className="col-md-6">
            <label>
              Subgrupo
            </label>
            <div className="mb-3">
              <select
                name="Certificate_id"
                value={selectedSubgroup}
                onChange={handleSubgorupChange}
                className="form-control"
              >
                <option value="">Selecciona un Subgrupo</option>
                {subgroups.map((subgroup) => (
                  <option key={subgroup.id_subgroup} value={subgroup.id_subgroup}>
                    {subgroup.id_subgroup} - {subgroup.subgroup_number}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <label>
              Certificado
            </label>
            <div className="mb-3">
              <select
                name="Certificate_id"
                value={selectedCertificate}
                onChange={handleCertificateChange}
                className="form-control"
              >
                <option value="">Selecciona un Certificado</option>
                {certificates.map((certificate) => (
                  <option key={certificate.id_certificate} value={certificate.id_certificate}>
                    {certificate.id_certificate} - {certificate.certificate_number}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {dataFetched && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Número del Certificado</th>
                <th scope="col">Status</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido Materno</th>
                <th scope="col">Apellido Paterno</th>
                <th scope="col">Titular</th>
                <th scope="col">PDF</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>

              {insureds.map((insured) => (
                <tr key={insured.id_insured}>
                  <td>{insured.certificate?.certificate_number}</td>
                  <td>{insured.certificate?.status}</td>
                  <td>{insured.first_name}</td>
                  <td>{insured.last_name_paternal}</td>
                  <td>{insured.last_name_maternal}</td>
                  <td>{insured.rol === 'holder' ? 'Sí' : 'No'}</td>
                  <td>
                    {insured.certificate?.pdfs.map((pdf, index) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <a href="#" onClick={() => openPdf(pdf.public_link)}>
                          <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                        </a>
                        <button
                          className="btn btn-danger btn-sm"
                          style={{ position: 'absolute', top: '0', right: '0', padding: '0.2rem' }}
                          onClick={() => handleDeletePDF(insured.certificate?.id_certificate, pdf.id)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm me-1"
                      onClick={() => handleEdit(insured.certificate?.id_certificate)}
                    >
                      Baja
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(insured.certificate?.id_certificate)}
                    >
                      Modificación
                    </button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        )}
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enivar Notificacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Numero del subgrupo</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formData.subgroup_number}
                  onChange={(e) => handleFieldChange('subgroup_number', e.target.value)}
                  disabled={!edit}
                />
              </Form.Group>

              <Form.Group controlId="formRFC">
                <Form.Label>Nombre del subgrupo</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formData.subgroup_name}
                  onChange={(e) => handleFieldChange('subgroup_name', e.target.value)}
                  disabled={!edit}
                />
              </Form.Group>
              {/* Mostrar sección para subir más PDFs */}
              <Form.Group controlId="formPDFs">
                <Form.Label>Añadir PDF</Form.Label>
                <div
                  {...getRootProps()}
                  style={isDragActive ? activeDropzoneStyle : dropzoneStyle}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>Suelta los archivos aquí...</p> :
                      <p>Arrastra y suelta archivos PDF aquí, o haz clic para seleccionar archivos</p>
                  }
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="ms-auto">
              {!edit ? (
                <Button variant="primary" onClick={handleEditSecundary} className="me-2">
                  Editar
                </Button>
              ) : (
                <div>
                  <Button variant="success" onClick={handleSaveEdit} className="me-2">
                    Guardar
                  </Button>
                  <Button variant="danger" onClick={handleCancelEdit}>
                    Cancelar
                  </Button>
                </div>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
//Estilos
const dropzoneStyle = {
  border: '2px dashed #6c757d', /* Default border color (secondary) */
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: '10px'
};

const activeDropzoneStyle = {
  ...dropzoneStyle,
  borderColor: '#007bff' /* Border color when active (primary) */
};

export default InsuredList;
