import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los ejecutivos
export const getLinksAnalytics = async () => {
  console.log("Entrando a getAnalytics");
  try {
    const response = await axios.get(`${apiUrl}/analytics/links`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getAnalytics:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error getAnalytics:', error);
    throw error;
  }
};