import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { openPdf } from '../../features/pdfs/functionsPdf';
import { pdfLogo } from '../../global';
import Accordion from 'react-bootstrap/Accordion';
import { findPolicyByIdPolicyData, uploadDeclaration } from '../../api/policyService';
import { Button, Form, Modal } from 'react-bootstrap';
import MainTableInsured from '../Tables/TableInsured/TableListInsured';
import { getSubgroupByPolicyId } from '../../api/SubgroupService';
import AddIcon from '@mui/icons-material/Add';
import SubgroupForm from '../SubgroupForm';
import InsuredForm from '../InsuredForm';
import CertificatesForm from '../CertificatesForm';
import SubgroupExcelUpload from '../Excel/SubgroupExcelUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ActionsPanel from '../ActionsPanel';
import { useLocation, useParams } from 'react-router-dom';

function PolicyDetails({ policyData, id }) {
    //settear localPolicyData con los datos de la poliza desde el modal de info general de la poliza
    const [localPolicyData, setLocalPolicyData] = useState(policyData);
    const [selectedFile, setSelectedFile] = useState(null);
    const [policyId, setPolicyId] = useState('');
    const [subgroups, setSubgroups] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showSubgroup, setShowSubgroup] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const [showInsured, setShowInsured] = useState(false);
    const [showExcelUpload, setShowExcelUpload] = useState(false);
    const [insuredToSearch, setInsuredToSearch] = useState(null);
    const location = useLocation(); 

    console.log("data desde policyDetails", localPolicyData)
    const fetchPolicyData = async () => {
        const response = await findPolicyByIdPolicyData(id);
        console.log("response from 34", response)
        setLocalPolicyData(response);
    }
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const formattedDate = format(parseISO(dateString), 'dd-MMMM-yy', { locale: es });
        const [day, month, year] = formattedDate.split('-');
        const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
        return `${day}-${capitalizedMonth}-${year}`;
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        fetchSubgroupsByPolicy()
        if (!policyData) {
            fetchPolicyData();
        }
        if (searchParams.has('fullName')) {
            const name = searchParams.get('fullName');
            setInsuredToSearch(name); 
            console.log('Nombre completo obtenido:', name);
        }
    }, [policyData, policyId]);
    const fetchSubgroupsByPolicy = async () => {
        const response = await getSubgroupByPolicyId(localPolicyData?.id_policy)
        setSubgroups(response)
    }
    const filterPdfsByCategory = (categoryKey) => {
        return localPolicyData?.pdfs.filter((pdf) => pdf.category === categoryKey);
    };
    const handleFileChange = (event) => {

        console.log('file', event.target.files[0])
        setSelectedFile(event.target.files[0]);
    };
    const uploadPDFDeclaration = async (id, data) => {
        try {
            const response = await uploadDeclaration(policyId, selectedFile); // Pasar ID y archivo al handler
            console.log(response)
            alert('Subida Exitosa')
            closeUploadModal();
        } catch (error) {
            alert(error)
        }
    }
    const openUploadModal = (policyId) => {
        setPolicyId(policyId); // Guardar el ID del 
        setShowUploadModal(true);
    };
    const closeUploadModal = () => {
        setShowUploadModal(false);
        setSelectedFile(null);
        setPolicyId(null)
    };
    const categoryMap = {
        pdf_declaration: 'Caratula',
        pdf_endorsement: 'Endoso',
        pdf_receipt: 'Recibo',
        pdf_policy_bill: 'Factura',
        pdf_others: 'Otros',
        ...(localPolicyData?.policy_type === 'GMM Individual' ? {
            pdf_digital_credentials: 'Credencial',
            pdf_presentation: 'Presentación'
        } : {})
    };

    const unifiedCategoryMap = {
        ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_declaration')
            ? { pdf_declaration: 'Carátula' }
            : { Caratula: 'Carátula' }),
        ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_endorsement')
            ? { pdf_endorsement: 'Endoso' }
            : { Endoso: 'Endoso' }),
        ...(localPolicyData?.policy_type === 'GMM Individual' ? {
            ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_certificate')
                ? { pdf_certificate: 'Certificado' }
                : { Certificado: 'Certificado' }),
            ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_digital_credentials')
                ? { pdf_digital_credentials: 'Credencial' }
                : { Credencial_Digital: 'Credencial' }),
            ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_presentation')
                ? { pdf_presentation: 'Presentación' }
                : { Presentacion: 'Presentación' }),
        } : {}),
        ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_receipt')
            ? { pdf_receipt: 'Recibo' }
            : { Recibo: 'Recibo' }),
        ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_policy_bill')
            ? { pdf_policy_bill: 'Factura' }
            : { Factura: 'Factura' }),
        ...(localPolicyData?.pdfs?.some((pdf) => pdf.category === 'pdf_others')
            ? { pdf_others: 'Otros' }
            : { Otros: 'Otros' }),
    };

    const filterPdfsByUnifiedCategory = (categoryKey) => {
        const unifiedCategory = unifiedCategoryMap[categoryKey];
        return localPolicyData?.pdfs?.filter((pdf) =>
            unifiedCategoryMap[pdf.category] === unifiedCategory
        ) || [];
    };


    // Si los datos aún no están disponibles, puedes mostrar un mensaje de carga
    if (!localPolicyData) {
        return <div>Cargando la informacion de la Póliza...</div>;
    }

    return (
        <div>


            <ActionsPanel policyData={localPolicyData} updateData={fetchPolicyData} />
            <div className="d-flex mb-4">

                <div className="card shadow p-3  bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <h5 className="mb-0">Datos Generales de la Aseguradora</h5>
                        <img
                            src={localPolicyData.insurer.logo_link}
                            alt="Logo Aseguradora"
                            style={{ width: '90px', height: 'auto' }}
                        />
                    </div>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>No. de Póliza</span> <span>{localPolicyData?.policy_number}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Compañía</span> <span>{localPolicyData?.insurer.commercial_name}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Clave Agente</span> <span>{localPolicyData?.insurer.agent_key}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Ramo</span> <span>{localPolicyData?.policy_branch}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>RFC</span> <span>{localPolicyData?.insurer.rfc}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Nombre</span> <span>{localPolicyData?.pool.name}</span>
                        </div>


                    </div>
                </div>
                <div className="card shadow p-3 mx-3 bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Datos generales de la Póliza</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>Numero de Póliza</span> <span>{localPolicyData?.policy_number}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Inicio</span> <span>{formatDate(localPolicyData?.start_date_coverage)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Vigencia</span> <span>{formatDate(localPolicyData?.end_date_coverage)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo de Póliza</span> <span>{localPolicyData?.policy_type}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Estatus</span> <span>{localPolicyData?.status}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Creado</span> <span>{formatDate(localPolicyData?.created_at)}</span>
                        </div>
                    </div>
                </div>
                <div className="card shadow p-3 mb-3 bg-white rounded" style={{ width: '30%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Contratante</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Nombre:</span> <span >{localPolicyData?.policy_holder?.business_name}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>RFC:</span> <span >{localPolicyData?.policy_holder?.rfc}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Direccion:</span> <span >{localPolicyData?.policy_holder?.fiscal_address}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span style={{ marginRight: '30%' }}>Administrador de la Póliza:</span> <span >{localPolicyData?.admin_email || 'Sin Dato'} </span>
                        </div>

                    </div>
                </div>

            </div>
            {/* {localPolicyData.policy_type == 'GMM Individual' && (
                <div className="d-flex">
                    <div className="card shadow p-3 bg-white rounded" style={{ width: '100%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                        <h5 className="mb-0">Datos de Asegurado</h5>
                        {localPolicyData?.policy_holder}
                        <hr />
                        <div className="mb-3">
                            <div className="d-flex justify-content-between mb-2">
                                <span>Nombre</span> <span>{localPolicyData?.policy_holder.business_name}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>RFC</span> <span>{localPolicyData?.policy_holder.rfc}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>Direccion</span> <span>{localPolicyData?.policy_holder.fiscal_address}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}


            {/* <div className="d-flex">
                <div className="card shadow p-3 mb-3 bg-white rounded" style={{ width: '40%', maxWidth: '800px', padding: '16px', borderRadius: '8px' }}>
                    <h5 className="mb-0">Primas</h5>
                    <hr />
                    <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span>Prima Total: </span> <span>{Number(localPolicyData?.total_premium)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Prima Neta: </span>
                            <span>{Number(localPolicyData?.net_premium)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                            <span>Financiamiento: </span>
                            <span>
                                {Number(localPolicyData?.financing || localPolicyData?.fractional_payment_surcharge)
                                    ?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Porcentaje de Comision: </span> <span>{localPolicyData?.commission_percentage}%</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Metodo de Pago: </span> <span>{localPolicyData?.payment_method}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Bonus 1</span>
                            <span>{Number(localPolicyData?.bonus1)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Bonus 2</span>
                            <span>{Number(localPolicyData?.bonus2)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Renovable: </span> <span>{localPolicyData?.renewable}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo de administracion: </span> <span>{localPolicyData?.administration_type}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Derecho de Poliza:</span>
                            <span>{Number(localPolicyData?.right_fee)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>IVA: </span>
                            <span>{Number(localPolicyData?.iva)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <span>Tipo: </span>
                            <span>
                                {localPolicyData?.type === 'LC' ? 'Líneas Comerciales' :
                                    localPolicyData?.type === 'LP' ? 'Líneas Personales' :
                                        'Tipo Desconocido'}
                            </span>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                            <span>Moneda:</span> <span>{localPolicyData?.currency}</span>
                        </div>

                    </div>
                </div>

            </div> */}
            <div className="d-flex">
                {localPolicyData.type == 'LC' && (
                    <div className="card shadow bg-white rounded" style={{ width: '40%', padding: '16px', marginRight: '10px', borderRadius: '8px' }}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header>Subgrupos</Accordion.Header>
                                <Accordion.Body>
                                    <div className="mb-3" style={{ borderRadius: '8px' }}>
                                        {subgroups && (
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Numero</th>
                                                        <th>Presentacion</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subgroups.map((subgroup, index) => (
                                                        <tr key={index}>
                                                            <td>{subgroup.subgroup_name}</td>
                                                            <td>{subgroup.subgroup_number}</td>
                                                            <td>
                                                                {subgroup?.pdfs?.length > 0 ? (
                                                                    <a href={subgroup.pdfs[0]?.public_link} target="_blank" rel="noopener noreferrer">
                                                                        <img src={pdfLogo} alt="Presentación" style={{ width: '40px', marginRight: '10px' }} />
                                                                    </a>
                                                                ) : (
                                                                    <span>Sin presentación</span>
                                                                )}

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                )}

                <div className="card shadow bg-white rounded" style={{ width: '100%', padding: '16px', borderRadius: '8px' }}>
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item >
                            <Accordion.Header>Archivos</Accordion.Header>
                            <Accordion.Body>
                                <div className="mb-3" style={{ borderRadius: '8px' }}>
                                    {localPolicyData.pdfs?.length <= 0 && (
                                        <div>
                                            No hay Archivos para esta Póliza
                                            <br />
                                            <Button className="btn btn-secondary btn-lg me-1 mb-1" onClick={() => openUploadModal(localPolicyData.id_policy)}>Cargar Caratula</Button>
                                        </div>

                                    )}
                                    {localPolicyData.pdfs?.length > 0 && (
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {Array.from(new Set(Object.values(unifiedCategoryMap))).map((category, index) => (
                                                        <th key={index}>{category}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {Array.from(new Set(Object.keys(unifiedCategoryMap))).map((categoryKey, index) => (
                                                        <td key={index} style={{ maxWidth: '130px', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {filterPdfsByUnifiedCategory(categoryKey).map((pdf, pdfIndex) => (
                                                                    <div key={pdfIndex} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', margin: '6px' }}>
                                                                        <a onClick={() => openPdf(pdf.public_link)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                                                            <img src={pdfLogo} alt="PDF" style={{ width: '33px', alignItems: 'center' }} />
                                                                            <span style={{ fontSize: '10px', fontWeight: 'bold', paddingLeft: '10px' }}>{pdf.file_name}</span>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="card shadow bg-white rounded mt-3" style={{ width: '100%', padding: '16px', borderRadius: '8px' }}>

                {/* <Accordion defaultActiveKey="2">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Asegurados</Accordion.Header>
                        <Accordion.Body>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion> */}
                <MainTableInsured policy={localPolicyData} insuredToSearch={insuredToSearch}/>

            </div>
            <Modal show={showUploadModal} onHide={closeUploadModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Subir Caratula PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Seleccionar archivo PDF:</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeUploadModal}>
                        Cancelar
                    </Button>
                    {selectedFile && (

                        <Button variant="primary" accept="application/pdf" onClick={uploadPDFDeclaration}>
                            Subir
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default PolicyDetails;
