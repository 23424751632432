import React, { useState, useEffect } from 'react';
import { deletePolicy, getPolicies, getPolicyDetails, updatePolicy } from '../api/policyService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getOrganizations } from '../api/OrganizationService';
import { getHolderByOrganization, getPoliciesByHolder } from '../api/PolicyHolderService';
import { Modal, Button, Form } from 'react-bootstrap';
import { deletePDF } from '../api/PdfService';

import { pdfLogo } from "../global";

import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
const MySwal = withReactContent(Swal);

function PolicyDetailsList() {
    const dispatch = useDispatch();
    const organization = useSelector((state) => state.organizations.data);
    const user = useSelector((state) => state.users.data);
    const [policies, setPolicies] = useState();
    const [editingId, setEditingId] = useState(null);
    const [originalData, setOriginalData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [policyHolders, setPolicyHolders] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [orgDatas, setOrgDatas] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedPolicyHolder, setSelectedPolicyHolder] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [edit, setEdit] = useState(false);

    const [policyNumber, setPolicyNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSubgroup, setShowSubgroup] = useState(null);
    const [expandedSubgroup, setExpandedSubgroup] = useState(null);
    const [selectedSubgroup, setSelectedSubgroup] = useState(null);

    const canDeleteOrCreate = user === 'admin' || user === 'executive';

    useEffect(() => {
        if (!organization.length) {
            infoGlobal.fetchOrganizations();
        } else {
            setOrgDatas(organization)
        }
        if (!user.length) {
            infoGlobal.fetchRole();
        }
    }, [organization, user, dispatch]);

    const handleOrganizationChange = async (event) => {
        try {
            const selectedOrgId = event.target.value;
            console.log("selectedOrgId", selectedOrgId);
            setSelectedOrganization(selectedOrgId);
            //obtener los policyHOLder dado el id de la organizacion
            const data = await getHolderByOrganization(selectedOrgId);
            setPolicyHolders(data.holders);
        } catch (error) {
            console.error('Error handling organization change:', error);
        }
    };

    const handlePolicyHolderChange = async (event) => {
        try {
            const selectedPolicyHolder = event.target.value;
            console.log("selectedPolicyHolder", selectedPolicyHolder);
            setSelectedPolicyHolder(selectedPolicyHolder);
            //obtener las polizas dado el id del policy holder
            const data = await getPoliciesByHolder(selectedPolicyHolder);
            console.log("Data de getPoliciesByHolder", data)
            setPolicies(data);
            setDataFetched(true);
        } catch (error) {
            console.error('Error handling policyHolder change:', error);
        }
    };

    const fetchPolicies = async () => {
        if (policyNumber) {
            try {
                const data = await getPolicyDetails(policyNumber);
                setPolicies(data);
                setDataFetched(true);
            } catch (error) {
                console.error('Error fetching policies:', error);
            }
        } else if (selectedPolicyHolder) {
            const data = await getPoliciesByHolder(selectedPolicyHolder);
            setPolicies(data);
        }
    };

    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };

    const toggleSubgroup = (subgroupId) => {
        if (expandedSubgroup === subgroupId) {
            setExpandedSubgroup(null);
        } else {
            setExpandedSubgroup(subgroupId);
        }
    };
    const renderCertificates = (certificates) => {
        return certificates.map((certificate) => (
            <div key={certificate.id_certificate} className="card mb-3">
                <div className="card-header bg-light">
                    <h5 className="mb-0"> <strong>No. Certificado: </strong>{certificate.certificate_number}</h5>
                </div>
                <div className="card-body">
                    <strong>Estado:</strong> {certificate.status}<br />
                    <strong>Titular:</strong> {certificate.holder}<br />
                    <strong>Creado:</strong> {certificate.created_at}<br />
                    <strong>Actualizado:</strong> {certificate.updated_at}<br />
                    {certificate.pdfs.map((pdf, index) => (
                        <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                            <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };
    const toggleCertificates = (subgroupId) => {
        if (selectedSubgroup === subgroupId) {
            setSelectedSubgroup(null); // Si es el mismo subgrupo, ocultarlo
        } else {
            setSelectedSubgroup(subgroupId); // Mostrar el subgrupo seleccionado
        }
    };
    const handleDeletePDF = async (id, pdfId) => {
        try {
            await deletePDF(pdfId);
            console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
            alert("anadir el recargar las polizas")
        } catch (error) {
            console.error('Error deleting PDF:', error);
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al eliminar el PDF.',
            });
        }
    };
    return (
        <div className="w-100 px-3 mx-auto mt-4">
            <div className="card">
                <div className="card-header bg-primary text-white">Buscar Póliza</div>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="policyNumber" className="col-sm-3 col-form-label">Número de Póliza:</label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                className="form-control"
                                id="policyNumber"
                                value={policyNumber}
                                onChange={(e) => setPolicyNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-sm-3">
                            <button onClick={fetchPolicies} type="submit" className="btn btn-primary">Buscar</button>
                        </div>
                    </div>

                    {loading && <p className="mt-3">Cargando...</p>}
                    {error && <p className="mt-3 text-danger">{error}</p>}

                    {policies && (
                        <div className="mt-4">
                            <h3 className="mb-4">Información de la Póliza</h3>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <strong>ID:</strong> {policies.id_policy}<br />
                                    <strong>Número de Póliza:</strong> {policies.policy_number}<br />
                                    <strong>Inicio de Cobertura:</strong> {policies.start_date_coverage}<br />
                                    <strong>Fin de Cobertura:</strong> {policies.end_date_coverage}<br />
                                    <strong>Tipo de Póliza:</strong> {policies.policy_type}<br />

                                </div>
                                <div className="col-md-4">
                                    <strong>Creado:</strong> {policies.created_at}<br />
                                    <strong>Ultima Actualizacion:</strong> {policies.updated_at}<br />
                                    <strong>Prima Neta:</strong> {policies.net_premium}<br />
                                    <strong>Prima Total:</strong> {policies.total_premium}<br />
                                    <strong>Metodo de Pago:</strong> {policies.payment_method}<br />
                                </div>
                                <div className="col-md-4">
                                    <strong>Creado:</strong> {policies.created_at}<br />
                                    <strong>Ultima Actualizacion:</strong> {policies.updated_at}<br />
                                    <strong>Prima Neta:</strong> {policies.net_premium}<br />
                                    <td>
                                        {policies.pdfs.map((pdf, index) => (
                                            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                                <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                    <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                                                </a>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    style={{ position: 'absolute', top: '0', right: '0', padding: '0.2rem' }}
                                                    onClick={() => handleDeletePDF(policies.id_policy, pdf.id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </td>
                                </div>
                            </div>

                            <div className="mt-4">
                                <h4>Subgrupos</h4>
                                <div className="row">
                                    {policies.subgroups.map((subgroup) => (
                                        <div key={subgroup.id_subgroup} className="col-md-4 mb-3">
                                            <div className="card">
                                                <div className="card-header bg-danger text-white">
                                                    <h5 className="mb-0">{subgroup.subgroup_name}</h5>
                                                </div>
                                                <div className="card-body">
                                                    <strong>Número de Subgrupo:</strong> {subgroup.subgroup_number}<br />
                                                    <strong>Nivel Hospitalario:</strong> {subgroup.hospital_level}<br />
                                                    <strong>Creado:</strong> {subgroup.created_at}<br />
                                                    <strong>Ultima Actualizacion: </strong> {subgroup.updated_at}<br />
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                        onClick={() => toggleCertificates(subgroup.id_subgroup)}
                                                    >
                                                        Mostrar Certificados
                                                    </button>
                                                    <div className={`mt-3 ${selectedSubgroup === subgroup.id_subgroup ? 'show' : 'collapse'}`} id={`certificates-${subgroup.id_subgroup}`}>
                                                        {renderCertificates(subgroup.certificates)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default PolicyDetailsList;
