import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { closeModal } from '../../features/modalSlice/modalPolicySlice';
import SubgroupForm from '../SubgroupForm';
import CertificatesForm from '../CertificatesForm';
import { handleDeletePDF, openPdf } from '../../features/pdfs/functionsPdf';
import { pdfLogo } from "../../global";
import InsuredForm from '../InsuredForm';
import OrganizationForm from '../Organization/OrganizationForm';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Card } from 'react-bootstrap';
import ModalRenewPolicy from '../Policies/ModalRenewPolicy';

const ModalActionsPolicies = () => {
  const dispatch = useDispatch();
  const { isOpen, policyData } = useSelector((state) => state.modalPolicy);
  const [showOrgForm, setShowOrgForm] = useState(false);
  const [showSubgroup, setShowSubgroup] = useState(false);
  const [showCertificates, setShowCertificates] = useState(false);
  const [showInsured, setShowInsured] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const formattedDate = format(parseISO(dateString), 'dd-MMMM-yy', { locale: es });
    const [day, month, year] = formattedDate.split('-');
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    return `${day}-${capitalizedMonth}-${year}`;
  };
  console.log("POlicyData", policyData)
  const handleClose = () => {
    dispatch(closeModal());
    setShowSubgroup(false);
  };

  const handleSubgroupOpen = () => setShowSubgroup(true);
  const handleSubgroupClose = () => setShowSubgroup(false);
  const handleCertificatesOpen = () => setShowCertificates(true);
  const handleCertificateClose = () => setShowCertificates(false);
  const handleInsuredOpen = () => setShowInsured(true);
  const handleInsuredClose = () => setShowInsured(false);
  const handleRenewPolicyOpen = () => setShowRenewModal(true)
  const handleRenewPolicyClose = () => setShowRenewModal(false)

  if (!policyData) return null;

  const styles = {
    button: {
      margin: '5px',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '14px',
      color: 'white',
      textDecoration: 'none',
      justifyContent: 'space-between',
      fontSize: '18px',
      marginRight: '20px',
    },
  };

  return (
    <div>
      <Modal show={isOpen} onHide={handleClose} size="xl">
        <Modal.Header closeButton style={{ backgroundColor: '#17a2b8', color: 'white', padding: '10px' }}>
          <Modal.Title >
            Resumen de la Póliza
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={4}>
                <p><strong>Póliza:</strong> {policyData?.policy_number}</p>
                <p><strong>Titular:</strong> {policyData?.policy_holder?.business_name}</p>
                <p><strong>Pool:</strong> {policyData?.pool.name}</p>
                <p><strong>Emisión:</strong> {formatDate(policyData?.start_date_coverage)}</p>
                <p><strong>Vencimiento:</strong> {formatDate(policyData?.end_date_coverage)}</p>

              </Col>
              <Col md={4}>
                <p><strong>Fecha de Creación:</strong> {formatDate(policyData?.created_at)}</p>
                <p><strong>Concepto:</strong> {policyData?.concept}</p>
                <p><strong>Tipo de Póliza:</strong> {policyData?.policy_type}</p>
                <p><strong>Prima Total:</strong> {policyData?.total_premium}</p>
                <p><strong>Moneda:</strong> {policyData?.currency}</p>
                <p><strong>Forma de Pago:</strong> {policyData?.payment_method}</p>
                <div>
                  <span><strong>Caratula: </strong></span>

                  {policyData.pdfs
                    ?.filter(pdf => pdf.category === 'pdf_declaration' || pdf.category === 'Caratula')
                    .map((pdf, index) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <a href="#" onClick={() => openPdf(pdf.public_link)}>
                          <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                        </a>
                      </div>
                    ))}
                </div>


              </Col>
              <Col md={4}>
                <div className="image-placeholder" style={{ height: '40px', textAlign: 'center' }}>
                  <img
                    src={policyData.insurer.logo_link}
                    alt="Imagen de la Aseguradora"
                    style={{
                      width: '40%',
                      objectFit: 'fill',
                      position: 'relative',
                      top: 0,
                      left: '50px'
                    }}
                  />
                </div>

                {policyData?.policy_type === 'GMM Individual' && (
                  <div className='mt-5'>

                    {policyData?.email_logs && (
                      <div style={{ marginTop: "10px" }}>
                        <Card style={{ width: '100%', maxWidth: '600px', height: '200px' }}>
                          <Card.Header>Correos Enviados</Card.Header>
                          <Card.Body>
                            <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
                              {policyData?.email_logs?.map((log, index) => (
                                <div key={index} style={{ marginBottom: '15px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
                                  <strong>Email enviado a:</strong> {log.email} <br />
                                  <strong>Tipo de email:</strong> {log.subject} <br />
                                  <strong>Fecha:</strong> {new Date(log.created_at).toLocaleDateString()} <br />
                                </div>
                              ))}
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Link
                  to={`/policyDetails/${policyData.id_policy}`}
                  state={{ policyData }}
                  style={{ textDecoration: 'none' }}
                >
                  <Button style={{ ...styles.button, width: '100%' }}>
                    Ver más información
                  </Button>
                </Link>
                <Button style={{ ...styles.button, width: '50%' }} onClick={handleRenewPolicyOpen} disabled>
                  Renovar
                </Button>
                {/* <div className="d-flex flex-column">
                  {policyData?.policy_type != 'GMM Individual' && (
                    <div >
                      <div className=" flex-row" style={{ display: 'flex', alignItems: 'center', }}>
                        <div>
                          <Button style={{ ...styles.button, ...styles.createButton }} onClick={() => handleSubgroupOpen()}>
                            <AddIcon />
                            Crear un Subgrupo
                          </Button>
                          <Link to={`/subgroupsFetch/${policyData.id_policy}`} style={{ textDecoration: 'none' }}>
                            <Button style={{ ...styles.button, ...styles.consultButton }}>
                              <SearchIcon />
                              Ver Subgrupos
                            </Button>
                          </Link>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', }}>
                        <Button style={{ ...styles.button, ...styles.createButton }} onClick={() => handleCertificatesOpen(true)}>
                          <AddIcon />
                          Crear Certificado
                        </Button>
                        <Link to={`/certificatesFetch/${policyData.id_policy}`} style={{ textDecoration: 'none' }}>
                          <Button style={{ ...styles.button, ...styles.consultButton }}>
                            <SearchIcon />
                            Ver Certificados
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        style={{
                          ...styles.button,
                          ...styles.createButton,
                        }}
                        onClick={() => handleInsuredOpen()}
                      >
                        <AddIcon />
                        Crear Asegurado
                      </Button>
                      <Link to={`/insuredFetch/${policyData.id_policy}`} style={{ textDecoration: 'none' }}>
                        <Button
                          style={{
                            ...styles.button,
                            ...styles.consultButton,
                          }}
                        >
                          <SearchIcon />
                          Ver Asegurados
                        </Button>
                      </Link>
                    </div>

                  </div>
                </div> */}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {
        showSubgroup && (
          <SubgroupForm
            buttonAddForeign={true}
            organizationForeign={policyData?.organization_id}
            policyHolderForeign={policyData?.policy_holder_id}
            policyForeign={policyData?.id_policy}
            onClose={handleSubgroupClose}
          />
        )
      }
      {
        showCertificates && (
          <CertificatesForm
            buttonAddForeign={true}
            organizationForeign={policyData?.organization_id}
            policyHolderForeign={policyData?.policy_holder_id}
            policyForeign={policyData?.id_policy}
            onClose={handleCertificateClose}
          />
        )
      }
      {
        showInsured && (
          <InsuredForm
            showModal={showInsured}
            policyForeign={policyData?.id_policy}
            policyData={policyData}
            onClose={handleInsuredClose}
          />
        )
      }
      {showOrgForm && <OrganizationForm />}
      {
        showRenewModal && (
          <ModalRenewPolicy
            showModal={showRenewModal}
            policyForeign={policyData?.id_policy}
            data={policyData}
            onClose={handleRenewPolicyClose}
          />
        )
      }
    </div >
  );
};

export default ModalActionsPolicies;
