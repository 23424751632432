import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { searchInsured } from '../../../api/InsuredService';

const SearchInsured = () => {
  const [formData, setFormData] = useState([])
  const [nameInsured, setNameInsured] = useState('')
  const [insureds, setInsureds] = useState(null);
  const [error, setError] = useState(null);


  const handleSearch = async () => {
    try {
      const response = await searchInsured({ name: nameInsured });
      setInsureds(response.data);
      setError(null)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('No se encontraron asegurados con el nombre proporcionado.');
      } else {
        console.error('Error al buscar asegurados:', error);
        setError('Ocurrió un error al realizar la búsqueda. Inténtalo nuevamente.');
      }
      setInsureds([]);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Buscar Asegurado</h1>

      {/* Formulario de búsqueda */}
      <Form onSubmit={(e) => { e.preventDefault(); }}>
        <Row className="justify-content-center">
          <Col md={6}>
            <Form.Group controlId="searchInput">
              <Form.Control
                type="text"
                placeholder="Escribe el nombre"
                value={nameInsured}

                onChange={(e) => setNameInsured(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="auto">
            <button onClick={handleSearch} type="submit" className="btn btn-primary">Buscar</button>

          </Col>
        </Row>
      </Form>
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
      <Row className="g-3 mt-3">
        {insureds &&
          insureds.map((insured) => (
            <Col xs={12} md={6} lg={4} key={insured.id_insured}>
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title className="mb-3">
                    {insured.first_name} {insured.last_name_paternal} {insured.last_name_maternal}
                  </Card.Title>

                  {insured.email && (
                    <Card.Text>
                      <strong>Email Personal:</strong> {insured.email}
                    </Card.Text>
                  )}

                  {insured.work_email && (
                    <Card.Text>
                      <strong>Email de Trabajo:</strong> {insured.work_email}
                    </Card.Text>
                  )}

                  {insured.phone_personal && (
                    <Card.Text>
                      <strong>Teléfono Personal:</strong> {insured.phone_personal}
                    </Card.Text>
                  )}

                  {insured.phone_office && (
                    <Card.Text>
                      <strong>Teléfono de Oficina:</strong> {insured.phone_office}
                    </Card.Text>
                  )}

                  {insured.date_birth && (
                    <Card.Text>
                      <strong>Fecha de Nacimiento:</strong> {insured.date_birth}
                    </Card.Text>
                  )}

                  {insured.gender && (
                    <Card.Text>
                      <strong>Género:</strong> {insured.gender}
                    </Card.Text>
                  )}

                  {insured.policy_enrollment_date && (
                    <Card.Text>
                      <strong>Fecha de Alta:</strong> {insured.policy_enrollment_date}
                    </Card.Text>
                  )}

                  {insured.policy_cancellation_date && (
                    <Card.Text>
                      <strong>Fecha de Baja:</strong> {insured.policy_cancellation_date}
                    </Card.Text>
                  )}

                  {insured.status && (
                    <Card.Text>
                      <strong>Estatus:</strong> {insured.status}
                    </Card.Text>
                  )}

                  {insured.current_antiquity && (
                    <Card.Text>
                      <strong>Antigüedad Actual:</strong> {insured.current_antiquity}
                    </Card.Text>
                  )}

                  {insured.total_antiquity && (
                    <Card.Text>
                      <strong>Antigüedad Total:</strong> {insured.total_antiquity}
                    </Card.Text>
                  )}

                  {insured.certificates && (
                    <>
                      <Card.Title className="mt-3 ">Certificados</Card.Title>
                      {insured.certificates.map((certificate) => (
                        <div key={certificate.id_certificate}>
                          <Card.Text>
                            <strong>Certificado:</strong> {certificate.certificate_number}
                          </Card.Text>
                          <Card.Text>
                            <strong>Subgrupo:</strong> {certificate.subgroup.subgroup_name}
                          </Card.Text>
                          <Card.Text>
                            <strong>Póliza:</strong>{" "}
                            <Link to={`/policyDetails/${certificate.subgroup.policy.id_policy}`}>
                              #{certificate.subgroup.policy.policy_number}
                            </Link>
                          </Card.Text>
                        </div>
                      ))}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>


    </div>
  );
};

export default SearchInsured;
