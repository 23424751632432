import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { getLinksAnalytics } from '../../../api/RedirectController';

const DashboardLinks = () => {
    // Datos de ejemplo
    const [data, setData] = useState([]);


    const fetchData = async () => {
        try {
            const response = await getLinksAnalytics();
            console.log('response', response)
            setData(response.data); // verificar
        } catch (error) {
            console.error('Error fetching getLinksAnalytics:', error);
        }
    };
    useEffect(() => {
        fetchData()
    }, []);
    // Contar clics por enlace
    // Opción 1: Usando reduce
    const linkCounts1 = data.reduce((acc, item) => {
        acc[item.to] = (acc[item.to] || 0) + 1;
        return acc;
    }, {});

    // Opción 2: Usando un Map
    const linkCounts2 = new Map();
    data.forEach((item) => {
        linkCounts2.set(item.to, (linkCounts2.get(item.to) || 0) + 1);
    });
    const linkCounts2Object = Object.fromEntries(linkCounts2);

    // Opción 3: Usando filter y length (menos eficiente pero simple)
    const uniqueLinks = [...new Set(data.map((item) => item.to))];
    const linkCounts3 = uniqueLinks.reduce((acc, link) => {
        acc[link] = data.filter((item) => item.to === link).length;
        return acc;
    }, {});

    // Elegir una opción para usar (por defecto: opción 1)
    const linkCounts = linkCounts1;

    // Datos para la gráfica
    const chartData = {
        labels: Object.keys(linkCounts),
        datasets: [
            {
                label: 'Clicks por enlace',
                data: Object.values(linkCounts),
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    return (
        <Container>
            <h1 className="my-4">Dashboard</h1>
            <Row>
                <Col md={6}>
                    <h4>Gráfica de clics por enlace</h4>
                    <Pie data={chartData} />
                </Col>
                <Col md={6}>
                    <h4>Resumen</h4>
                    <ul>
                        {Object.entries(linkCounts).map(([link, count]) => (
                            <li key={link}>
                                {link}: {count} clics
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="mt-4">Detalles de los clics</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>To</th>
                                <th>User Agent</th>
                                <th>Platform</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row) => (
                                <tr key={row.id}>
                                    <td>{row.id}</td>
                                    <td>{row.to}</td>
                                    <td>{row.user_agent}</td>
                                    <td>{row.platform}</td>
                                    <td>{row.created_at}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardLinks;
