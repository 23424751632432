import React, { useEffect, useMemo, useState } from 'react';
import { MRT_EditActionButtons, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Dialog, Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography , Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getInsuredsByPolicyId } from '../../api/InsuredService';
import { pdfLogo } from '../../global';
import { attachInsuredProcedure, createInsureds, deleteInsuredProcedure, getInsuredsProcedure, updateInsured, uploadFilesInsured } from '../../api/ProceduresService';
import { AttachFileOutlined } from '@mui/icons-material';
import { RenderDropzone } from '../../features/pdfs/functionsPdf';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)



const TableInsuredProcedure = ({ insureds, idProcedure, dataIntern }) => {
  const [userFiles, setUserFiles] = useState({ file1: null, file2: null, file3: null });
  const [id, setId] = useState({});
  const [data, setData] = useState(insureds);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const dataCount = new Set(data.map(item => item.policy_number)).size;

  console.log('informacion de data', data)
  console.log('informacion de dataIntern', dataIntern)
  console.log('informacion de insureds', insureds)
  console.log('informacion de idProcedure', idProcedure)
  console.log("dataCount",dataCount)
  const columns = useMemo(() => [
    { accessorKey: 'policy_number', header: 'Póliza', size: 10 },
    { accessorKey: 'certificate_number', header: 'Certificado', size: 10 },
    { accessorKey: 'subgroup_number', header: 'Subgrupo', size: 10 },
    { accessorKey: 'first_name', header: 'Nombre', size: 10 },
    { accessorKey: 'last_name_paternal', header: 'Apellido Paterno', size: 10 },
    { accessorKey: 'last_name_maternal', header: 'Apellido Materno', size: 10 },
    {
      accessorKey: 'relationship',
      header: 'Relación',
      size: 10,
      editVariant: 'select',
      editSelectOptions: [
        { value: 'holder', label: 'Titular' },
        { value: 'spouse', label: 'Conyuge' },
        { value: 'dependent', label: 'Dependiente' },
      ],
    },
    {
      accessorKey: 'birth_date',
      header: 'Nacimiento',
      size: 10,
      type: "date",

      editComponent: props => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <DatePicker
            format="dd/MM/yyyy"
            value={props.value || null}
            onChange={props.onChange}
            clearable
            InputProps={{
              style: {
                fontSize: 13,
              }
            }}
          />
        </LocalizationProvider>
      )
    },
    {
      accessorKey: 'gender',
      header: 'Género',
      size: 10,
      editVariant: 'select',
      editSelectOptions: [
        { value: 'M', label: 'Masculino' },
        { value: 'F', label: 'Femenino' },
      ],
    },
    {
      accessorKey: 'antiquity_date',
      header: 'Antigüedad',
      size: 10,
    },
    {
      accessorKey: 'email',
      header: 'Correo',
      size: 10,
    },
    {
      accessorKey: 'start_date',
      header: 'Alta',
      size: 10,
      muiTableBodyCellEditTextFieldProps: {
        type: 'date',
      },
    },
    {
      accessorKey: 'end_date',
      header: 'Baja',
      size: 10,
      muiTableBodyCellEditTextFieldProps: {
        type: 'date',
      },
    },
    { accessorKey: 'net_premium', header: 'Prima Neta', size: 10 },
    { accessorKey: 'financing', header: 'Financiamiento', size: 10 },
    { accessorKey: 'policy_fee', header: 'Derecho de Po', size: 10 },
    { accessorKey: 'iva', header: 'IVA', size: 10 },
    { accessorKey: 'total_premium', header: 'Prima Total', size: 10 },
    { accessorKey: 'refund_net_premium', header: 'Dev Prima Total', size: 10 },
    { accessorKey: 'refund_financing', header: 'Dev Financiamiento', size: 10 },
    { accessorKey: 'refund_iva', header: 'Dev IVA', size: 10 },
    { accessorKey: 'refund_total_premium', header: 'Dev Prima Total', size: 10 },
    {
      id: 'pdf_certificate',
      header: 'Cert',
      size: 50,
      enableEditing: false,
      accessorFn: (row) => {
        const pdfs = row.pdfs || [];
        const filteredPdfs = pdfs.filter(pdf => pdf.category === 'certificate' || pdf.category === 'Certificado');
        if (filteredPdfs.length > 0) {
          return filteredPdfs.map(pdf => (
            <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
              <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
            </a>
          ));
        } else {
          return 'NA';
        }
      },
    },
    {
      id: 'pdf_credential',
      header: 'Credencial',
      size: 50,
      enableEditing: false,

      accessorFn: (row) => {
        const pdfs = row.pdfs || [];
        const filteredPdfs = pdfs.filter(pdf => pdf.category === 'credential');
        if (filteredPdfs.length > 0) {
          return filteredPdfs.map(pdf => (
            <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
              <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
            </a>
          ));
        } else {
          return 'NA';
        }
      },
    },
    {
      id: 'cardAntiquity',
      header: 'Antiguedad',
      size: 50,
      enableEditing: false,

      accessorFn: (row) => {
        const pdfs = row.pdfs || [];
        const filteredPdfs = pdfs.filter(pdf => pdf.category === 'cardAntiquity');
        if (filteredPdfs.length > 0) {
          return filteredPdfs.map(pdf => (
            <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
              <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
            </a>
          ));
        } else {
          return 'NA';
        }
      },
    },
    {
      id: 'others',
      header: 'Otros',
      size: 50,
      enableEditing: false,

      accessorFn: (row) => {
        const pdfs = row.pdfs || [];
        const filteredPdfs = pdfs.filter(pdf => pdf.category === 'others');
        if (filteredPdfs.length > 0) {
          return filteredPdfs.map(pdf => (
            <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
              <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
            </a>
          ));
        } else {
          return 'NA';
        }
      },
    },

  ], []);

  const fetchData = async () => {
    const response = await getInsuredsProcedure(idProcedure)
    console.log("Respuesta", response[0])
    setData(response[0]);
  }
  const handleFileChange = (updatedFiles, category) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [category]: updatedFiles
    }));
    console.log(category, updatedFiles);
  };


  const handleCreateInsured = async () => {
    try {
      const response = await createInsureds(data)
      console.log('response', response)
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Se han creado los ejecutivos y los certificados.',
      });
    } catch (error) {
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
        html: `Ha ocurrido un error al eliminar El certificado.<br/><strong>${error.response.data.message}</strong>`,
      });
    }
  }

  const handleSaveUser = async ({ values, table }) => {

    try {
      // Crear un objeto FormData
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (values[key] !== null) { // Verifica si el valor no es nulo
          formData.append(key, values[key]);
        }
      });
      const response = await updateInsured(id, formData)
      console.log('response', response)
      table.setEditingRow(null); // Salir del  modo de edición
      fetchData()

      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Se ha actualizado el asegurado en tramite.',
        footer: `<pre>${response.message || ''}</pre>`,
      });
    } catch (error) {
      console.log("error", error)

      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response || 'Error desconocido'}</pre>`,
        html: `Ha ocurrido un error al eliminar El certificado.<br/><strong>${error.response}</strong>`,
      });
    }
  };


  const openDeleteConfirmModal = async (row) => {
    console.log("row", row.original)
    if (window.confirm(`Se eliminara el asegurado: ${row.original.first_name} ${row.original.last_name_paternal} y sus archivos asociados`)) {
      const index = data.findIndex(user => user.id === row.original.id);
      if (index !== -1) {
        const response = await deleteInsuredProcedure(row.original.id)
        fetchData()
      }
    }
  };
  //Crear Asegurado
  const handleCreateUser = async ({ values, table }) => {
    const formData = new FormData();
    console.log('values', values)
    console.log('table', table)

    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    // Llamar a attachInsuredProcedure con el nuevo arreglo
    await attachInsuredProcedure(idProcedure, formData);
    table.setCreatingRow(null); // salir del modo de creación
    fetchData()

  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    getRowId: (row) => row.id,
    initialState: {
      density: 'compact', // Aquí defines la densidad por defecto
    },

    onEditingRowSave: handleSaveUser,
    onCreatingRowSave: handleCreateUser,

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
              setId(row.original.id);
            }}
          >
            <EditIcon />
          </IconButton>

        </Tooltip>
        <Tooltip>
          <IconButton
            onClick={() => {
              setOpen(true); // Abre el modal al hacer clic en el botón
              setId(row.original.id);
            }}
          >
            <AttachFileOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between" 
        sx={{ p: 2 }}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              table.setCreatingRow(true);
            }}
            sx={{ mr: 1 }} 
          >
            +
          </Button>
          <Button
            variant="contained"
            color="secondary" // Cambia el color según tus necesidades
            onClick={() => {
              handleCreateInsured();
            }}
          >
            Enviar
          </Button>
        </Box>

      </Box>
    ),
  });
  
  const handleUpload = async () => {
    // Imprimir los datos de formData en la consola
    console.log("Datos del FormData:");
    console.log(formData)
    const response = await uploadFilesInsured(id, formData)
    console.log(response)
    setOpen(false); // Cierra el modal
  };
  return (
    <>
      <MaterialReactTable table={table} />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle variant="h3">Cargar archivos </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

          <RenderDropzone
            onFilesChange={handleFileChange}
            fileName="Certificados"
            category="certificate"
            files={formData.certificate}
          />

          <RenderDropzone
            onFilesChange={handleFileChange}
            fileName="Credenciales"
            category="credential"
            files={formData.credential}
          />
          <RenderDropzone
            onFilesChange={handleFileChange}
            fileName="Antiguedad"
            category="antiquity"
            files={formData.antiquity}
          />

          <RenderDropzone
            onFilesChange={handleFileChange}
            fileName="Otros"
            category="others"
            files={formData.others}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
          <Button onClick={() => {
            // Lógica para manejar la subida de archivos cuando el modal se cierra
            handleUpload()

          }} color="primary">Upload</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default TableInsuredProcedure;
