import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { postCertificateMassive } from '../../api/MassiveDataService';
import { useDropzone } from 'react-dropzone';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import ReactDOMServer from 'react-dom/server';

// Incluye SweetAlert2 y otros módulos necesarios
const MySwal = withReactContent(Swal);

// Definir el estilo CSS dentro del JavaScript
const customCSS = `
    .custom-swal-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }
    .custom-swal-popup {
        width: 1450px;
        max-width: 2000px; 
        margin: 0 auto; /* Centra el popup horizontalmente */
        font-size: 15px; /* Tamaño de la fuente */
    }
    .custom-swal-title {
        font-size: 40px; /* Tamaño de la fuente del título */
    }
`;

// Crear un elemento <style> y agregarlo al <head> del documento
const styleElement = document.createElement('style');
styleElement.innerHTML = customCSS;
document.head.appendChild(styleElement);

const SubgroupExcelUpload = ({ policy_id, onClose }) => {
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState([]);
    const [policyId, setPolicyId] = useState(policy_id); // Inicializar con el prop
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(''); // Nuevo estado para el nombre del archivo
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPolicyId(policy_id); // Actualizar cuando el prop cambie
    }, [policy_id]); // Dependencia en el prop

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        maxFiles: 1, // Permitir solo un archivo
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                setFileName(selectedFile.name); // Guardar el nombre del archivo
                handleFileUpload({ target: { files: acceptedFiles } });
            }
        },
    });

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheet = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheet];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (jsonData.length > 0) {
                const headerRow = jsonData[0];
                const dataRows = jsonData.slice(1);
                setHeaders(headerRow);

                const mappedData = dataRows
                    .map(row => headerRow.reduce((acc, header, index) => {
                        acc[header] = String(row[index] || '').trim(); // Convertir a cadena y eliminar espacios
                        return acc;
                    }, {}))
                    .filter(row =>
                        Object.values(row).some(value =>
                            value !== '' // Verifica que no estén vacíos
                        )
                    );

                setData(mappedData);

            }
        };
        reader.readAsBinaryString(selectedFile);
        console.log("Archivo subido");
    };


    const handleSubmit = async () => {
        if (!policyId || !file) {
            if (!policyId) {
                alert('No hay Poliza seleccionada');
                return;
            }
            else {
                alert('No hay archivo seleccionado');
                return;
            }
        }

        try {
            // Crear una tabla HTML con los datos
            const tableHTML = `
                <div style="max-height: 300px; overflow-y: auto;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Subgrupo</th>
                                <th>Nombre Sub</th>
                                <th>Nivel Hosp.</th>
                                <th>Certificado</th>
                                <th>Nombre Completo</th>
                                <th>Relación</th>
                                <th>Email</th>
                                <th>Email Trabajo</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${data.map(item => `
                                <tr>
                                    <td>${item['subgroup_number'] || ''}</td>
                                    <td>${item['subgroup_name'] || 'NA'}</td>
                                    <td>${item['hospital_level'] || 'NA'}</td>
                                    <td>${item['certificate_number'] || ''}</td>
                                    <td>${item['first_name'] || ''} ${item['last_name_paternal'] || ''} ${item['last_name_maternal'] || ''}</td>
                                    <td>${item['relationship'] || ''}</td>
                                    <td>${item['email'] || ''}</td>
                                    <td>${item['work_email'] || ''}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>
            `;

            await MySwal.fire({
                icon: 'question',
                title: 'Confirmar Envío',
                html: `
                    <p>Se van a enviar los siguientes datos, ¿desea continuar?</p>
                    ${tableHTML}
                `,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí, Enviar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    container: 'custom-swal-container',
                    popup: 'custom-swal-popup',
                    title: 'custom-swal-title'
                },
                preConfirm: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.innerHTML = `
                        Enviando...
                        ${ReactDOMServer.renderToString(<CircularProgress size={16} color="inherit" />)}
                    `;
                    confirmButton.disabled = true;
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        // Enviar los datos
                        const response = await postCertificateMassive(policyId, data);
                        console.log("Response", response);

                        // Confirmación de éxito
                        await MySwal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Los Asegurados han sido guardados correctamente.',
                            confirmButtonText: 'OK'
                        });
                        onClose()
                    } catch (error) {
                        console.log("error", error);
                    
                        const errors = error.response?.data?.errors || {};  // Acceder a los errores
                        let errorMessage = '';  // Inicializar el mensaje de error
                    
                        // Verificar si hay errores
                        if (Object.keys(errors).length > 0) {
                            // Formatear los errores como una lista HTML
                            errorMessage = Object.entries(errors)
                                .map(([field, messages]) => {
                                    const [index, fieldName] = field.split('.');  // Extraer índice y nombre del campo
                                    return `
                                        <div style="padding: 10px; margin-bottom: 10px; border: 1px solid #e74c3c; border-radius: 5px; background-color: #f8d7da;">
                                            <p style="font-weight: bold; color: #e74c3c; margin: 0;">Fila ${parseInt(index) + 1} - ${fieldName}:</p>
                                            <ul style="margin: 5px 0 0 20px; color: #721c24;">
                                                ${messages.map(msg => `<li>${msg}</li>`).join('')}
                                            </ul>
                                        </div>
                                    `;
                                })
                                .join('');
                        } else {
                            errorMessage = 'Error desconocido';  // Si no hay errores definidos
                        }
                    
                        // Mostrar los errores en el alert
                        await MySwal.fire({
                            icon: 'error',
                            title: 'Error al cargar los datos',
                            html: `<div style="text-align: left; max-height: 400px; overflow-y: auto; font-family: Arial, sans-serif; font-size: 14px;">${errorMessage}</div>`,
                            showCloseButton: true,
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonText: 'OK',
                            width: '600px',
                        });
                    }
                }
            });



        } catch (error) {
            await MySwal.fire({
                icon: 'error',
                title: 'Error',
                html: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className="mt-4">
            <div
                {...getRootProps({
                    className: `dropzone border rounded p-4 ${isDragActive ? 'bg-light' : 'bg-white'
                        }`
                })}
            >
                <input {...getInputProps()} />
                <p className="text-center">
                    {isDragActive
                        ? 'Suelta el archivo aquí...'
                        : 'Suelta el archivo Excel o da clic para seleccionar'}
                </p>
                {fileName && <p className="text-center mt-2">Archivo seleccionado: <strong>{fileName}</strong></p>}
            </div>
            <div className="mt-3">
                {file && (
                    <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={handleSubmit}
                    >
                        Subir
                    </button>
                )}
            </div>
        </div>
    );
};

export default SubgroupExcelUpload;
